import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const CarSeatWhite = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path d="M129.987,329.24c4.267,21.333,23.04,36.693,44.373,36.693c3.413,0,6.827-0.853,9.387-0.853
				c24.747-5.12,48.64-7.68,70.827-7.68s46.08,2.56,69.973,7.68c24.747,5.12,48.64-11.093,53.76-35.84
				c0.853-2.56,0.853-5.973,1.707-8.533c0.853-6.827,0.853-14.507-0.853-21.333l-24.747-106.667
				c-4.267-17.067-17.067-29.867-33.28-34.133c-11.093-3.413-31.573-6.827-58.88-6.827c-3.413,0-5.973,0-5.973,0h-1.707h-2.56
				c0,0-2.56,0-5.973,0c-27.307,0-46.933,3.413-58.88,6.827c-17.067,4.267-29.013,17.92-33.28,34.133l-24.747,106.667
				c-1.707,7.68-1.707,14.507-0.853,21.333C129.133,324.12,129.133,326.68,129.987,329.24z M146.2,303.64l24.747-106.667
				c2.56-10.24,10.24-18.773,20.48-21.333s29.013-5.973,54.613-5.973c3.413,0,5.12,0,5.973,0h2.56h3.413c0,0,2.56,0,5.12,0
				c25.6,0,43.52,3.413,55.467,5.973c10.24,2.56,17.92,11.093,20.48,21.333L363.8,303.64c1.707,5.12,1.707,9.387,0.853,14.507
				c-0.853,2.56-0.853,5.12-1.707,7.68c-3.224,14.506-17.102,24.439-31.573,22.624v-25.184c0-5.12-3.413-8.533-8.533-8.533
				s-8.533,3.413-8.533,8.533v22.106c-5.733-0.941-11.421-1.744-17.067-2.421v-28.218c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v26.562c-5.735-0.423-11.425-0.709-17.067-0.851V306.2c0-5.12-3.413-8.533-8.533-8.533
				s-8.533,3.413-8.533,8.533v34.244c-5.651,0.142-11.344,0.43-17.067,0.854v-26.565c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v28.239c-5.674,0.691-11.366,1.513-17.067,2.477v-22.182c0-5.12-3.413-8.533-8.533-8.533
				s-8.533,3.413-8.533,8.533v25.262c-14.797,1.415-27.548-8.429-30.72-22.702c-0.853-2.56-1.707-5.12-1.707-7.68
				C144.493,313.88,145.347,308.76,146.2,303.64z"/>
          <path d="M468.76,424.813c0-14.507-5.12-27.307-14.507-35.84c-10.592-9.969-25.272-13.566-35.084-14.783
				c5.163-12.957,9.199-28.256,12.044-45.804c1.707-13.653,1.707-27.307-1.707-40.96l-30.72-128.853
				c-9.624-30.545-43.456-44.876-75.947-51.555V84.333h23.893c9.387,0,17.92-4.267,21.333-11.093c5.12-7.68,6.827-17.067,3.413-25.6
				l-11.093-30.72C356.12,5.827,345.88-1,334.787-1h-161.28c-11.093,0-21.333,6.827-25.6,17.067l-11.093,30.72
				c-2.56,8.533-1.707,17.92,3.413,25.6s12.8,11.947,22.187,11.947h23.893v22.642c-32.821,6.72-67.102,21.249-76.8,52.451
				l-29.867,128c-3.413,13.653-4.267,27.307-1.707,40.96c2.909,17.942,7.062,33.534,12.393,46.674
				c-9.893,1.233-24.037,4.844-34.58,14.766C46.36,399.213,41.24,411.16,41.24,426.52c0,0.83,5.657,79.874,102.4,84.279V511h8.533
				h8.533H348.44h8.533h8.533v-0.201C462.25,506.393,467.931,427.302,468.76,424.813z M153.88,63
				c-1.707-2.56-1.707-5.973-0.853-9.387l11.093-30.72c0.853-4.267,5.12-6.827,9.387-6.827H335.64c4.267,0,7.68,2.56,9.387,6.827
				l11.093,30.72c1.707,3.413,0.853,6.827-0.853,9.387c-1.707,2.56-5.12,4.267-8.533,4.267h-184.32
				C159,67.267,155.587,65.56,153.88,63z M203.373,84.333h102.4v19.794c-26.884-3.641-49.398-2.728-51.2-2.728
				c-2.144,0-24.501-0.895-51.2,2.7V84.333z M96.707,291.693l29.867-128c11.947-37.547,82.773-45.227,119.467-45.227
				c3.413,0,5.973,0,8.533,0c31.573-1.707,115.2,3.413,128,44.373l31.573,128.853c3.413,11.093,3.413,23.04,1.707,34.133
				c-2.166,12.996-5.86,29.657-12.243,44.621l-0.557-0.247l-4.267,7.68c-10.578,19.529-23.487,29.739-39.46,30.646
				c-9.632-3.474-51.815-16.601-101.733-16.975c-0.34-0.004-0.681-0.006-1.023-0.008c-0.382-0.002-0.761-0.009-1.144-0.009
				c-0.105,0-0.209,0.002-0.314,0.003c-0.18,0-0.359-0.003-0.539-0.003c-9.419,0-18.572,0.479-27.298,1.278
				c-0.04,0.004-0.081,0.007-0.121,0.011c-0.932,0.086-1.86,0.174-2.783,0.267c-37.478,3.669-66.185,12.773-73.012,15.495
				c-0.126-0.002-0.253-0.004-0.379-0.007c-37.287-1.996-50.876-52.124-55.98-82.751C93.293,314.733,94.147,302.787,96.707,291.693z
				 M58.307,425.667c0-10.24,2.56-17.92,8.533-23.893c10.226-9.374,25.555-10.237,31.546-10.24
				c8.445,14.337,18.901,24.296,31.232,29.609c4.476,2.08,9.151,3.596,14.022,4.524v68.267
				C63.427,489.667,58.307,428.227,58.307,425.667z M160.707,493.933v-70.827c13.618-4.256,51.006-14.451,93.536-14.504
				c0.603,0.001,1.205,0.003,1.805,0.008c0.088,0,0.176,0.001,0.264,0.002c42.38,0.407,79.032,10.668,92.129,14.645v70.676H160.707z
				 M365.507,493.08v-68.028c18.574-2.633,33.773-14.027,45.254-33.518c5.991,0.002,21.32,0.866,31.546,10.24
				c5.973,5.973,8.533,13.653,8.533,23.04C450.84,427.373,446.573,488.813,365.507,493.08z"/>
        </g>
      </g>
    </Svg>
  </SvgContainer>
)

CarSeatWhite.propTypes = {
  baseline: PropTypes.bool
}
