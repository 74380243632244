import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Check = props => (
  <SvgContainer>
    <Svg viewBox="0 0 1000 1000" {...props}>
      <g><g transform="translate(0.000000,442.000000) scale(0.100000,-0.100000)"><path d="M9519.1,3555.3c-1255.6-786.7-2450-1697.8-3617.6-2758.2C5237.4,192.3,4366.4-693.9,3788.4-1358.1c-134-153.1-248.8-279.4-256.5-281.4c-7.7-3.8-545.5,411.5-1196.3,922.6L1152.7,209.6L747-117.8C523-297.7,287.6-489.1,220.6-542.7L100-638.4l352.2-356c193.3-195.2,1115.9-1131.2,2051.9-2082.5c934.1-951.3,1705.4-1722.7,1713.1-1713.1c7.7,7.6,61.3,122.5,116.8,254.6c55.5,132.1,201,438.3,323.5,679.5C5557-2077.7,7036.6,27.7,8577.4,1721.7c120.6,132.1,467,488.1,771.3,790.5l551.3,551.3l-124.4,281.4c-68.9,155-126.3,283.3-128.2,285.2C9645.4,3631.9,9588,3597.4,9519.1,3555.3z"/></g></g>
    </Svg>
  </SvgContainer>
)

Check.propTypes = {
  baseline: PropTypes.bool
}
