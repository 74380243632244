import React from 'react';
import Mountain from '../../images/mountain.jpg';
import { LayoutContent, LayoutSection } from '../../ui/Layout';
import Stage from '../../ui/Stage/Stage';
import { H2, Paragraph } from '../../ui';
import pdfIcon from '../../images/pdf.gif';
import Image from '../../ui/Image/Image';
import { ChevronR } from '../../ui/Icon';
import { history } from '../../config/store';
import styled from 'styled-components';
import Button from '../../ui/Button/Button';

const ButtonStart = styled(Button)`
  margin: 0 auto;
  margin-top: 2rem;
`;

export function AGB() {
  return (
    <div>
      <Stage imgSrc={Mountain} alt={'Gruppenbus'} slogan={'AGB'} small />
      <LayoutContent>
        <LayoutSection centered>
          <H2>Allgemeine Geschäftsbedingungen</H2>
          <Paragraph>
            Stand: 01.01.2023
            <br />
            <a
              href="https://www.touralarm.de/Admin/AGB.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image imgSrc={pdfIcon} alt="PDF icon" maxWidth="16px" /> PDF
              Download der AGB
            </a>
          </Paragraph>
          <ButtonStart
            type="button"
            big
            rounded
            Icon={ChevronR}
            onClick={() => history.push('/')}
          >
            Zur Startseite
          </ButtonStart>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
