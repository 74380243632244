import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import CheckSvg from '../../ui/Icon/check.svg'

const Input = styled.input`
  appearance: none;
  color: ${colors.white};
  outline: none;
  cursor: pointer;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  border: 1px solid ${colors.silver};
  border-radius: 3px;
  background: ${colors.lightGrey};

  &:checked {
    background: url(${CheckSvg}) center center ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`
const Checkbox = ({ ...props }) => <Input type="checkbox" {...props} />

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.string
}

export default Checkbox
