import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import 'react-dates/lib/css/_datepicker.css';
import DateRangePickerPhrases from './locale';
import styled from 'styled-components';
import { colors } from '../../../ui/theme/colors';
import { breakpoints } from '../../../ui/theme/breakpoints';
import Media from 'react-media';
import Button from '../../../ui/Button/Button';
import { TextField } from '../../../ui/TextField';
import { zIndexes } from '../../../ui/theme/zIndexes';
import { ArrowRight, Calendar } from '../../../ui/Icon';
import * as ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import * as actions from '../../../actions';
import { DateHelper } from './DateHelper_2018-01-16';

const DatePickerWrapper = styled.span`
  position: relative;
  min-width: 26rem;
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    width: 35rem;
    margin-right: 2rem;
  }

  /*Datepicker Styles*/

  // Normal Style
  .DayPicker__withBorder {
    position: absolute;
    z-index: ${zIndexes.datePicker};
    top: ${(props) => (!!props.label ? '7rem' : '5rem')};
    border-bottom: 5px solid ${colors.primary};
  }

  // Fullscreen
  .DayPicker__verticalScrollable {
    z-index: ${zIndexes.datePicker};
    position: fixed;
    top: 8rem;
    left: 0;
    right: 0;
    bottom: 0;

    .DayPicker_transitionContainer__verticalScrollable {
      bottom: 19.4rem;
      height: auto;
    }

    .CalendarMonthGrid__vertical_scrollable {
      width: 90% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      -webkit-overflow-scrolling: touch; /*fixes IOS Scrolling Problem*/
    }

    .CalendarDay__default {
      padding: 2vh;
    }
  }

  /* First and last day */
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.black};
  }

  /* Span hover */
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${colors.primaryLight};
    border-color: ${colors.primaryLight};
    color: ${colors.black};
  }

  /* Selected after close */
  .CalendarDay__selected_span {
    background: ${colors.primaryLight};
    border-color: ${colors.primaryLight};
    color: ${colors.black};
  }

  /* Hover single day of middle range */
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.black};
  }

  .DateInput_input {
    border-bottom: none;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  line-height: 1.42rem;
  width: 100%;
  min-height: 2rem;

  color: ${(props) =>
    !!props.error ? colors.red : props.disabled ? colors.lightGrey : 'inherit'};
`;

const InputWrapper = styled.span`
  display: flex;
  align-content: stretch;
  width: 100%;

  > span {
    width: 16rem;
  }

  > span:nth-of-type(2) {
    width: 2rem;
    margin: 0 0.5rem;

    @media screen and (max-width: ${breakpoints.minPhone}) {
      width: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    > span {
      width: 100%;
    }
  }
`;

const CalendarInfo = styled.div`
  width: 100%;
  height: 12rem;

  z-index: ${zIndexes.datePicker + 1};
  bottom: 0;
  background: ${colors.white};

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${colors.lightGrey};

  @media screen and (max-width: ${breakpoints.minTablet}) {
    position: fixed;

    button {
      margin-top: 1rem;
      font-size: 2rem;
    }
  }

  button {
    margin-top: 1rem;
    padding: 1rem 5rem;
    font-size: 1.6rem;
  }
`;

moment.locale('de');

export function Datepicker({
  startDate,
  endDate,
  onOpen,
  onClose,
  onChange,
  label,
  error,
  small,
  ...datePickerProps
}) {
  const [focusedInput, setFocusedInput] = React.useState('startDate');
  const [startDateCurrent, setStartDate] = React.useState(startDate);
  const [endDateCurrent, setEndDate] = React.useState(endDate);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const datepickerRef = React.useRef();
  const dispatch = useDispatch();

  const onDatesChange = ({ startDate, endDate }) => {
    if (focusedInput === 'endDate' && !endDate) {
      alert(
        'Die Rückgabe liegt vor der Abholung. Bitte wähle einen späteren Termin!'
      );
      return;
    }
    const startDateWeekDay = moment(startDate).isoWeekday();
    if (startDateWeekDay === 7 || startDateWeekDay === 6) {
      alert(
        'Die Abholung ist leider nicht am Wochenende und an Feiertagen möglich! Bitte wähle einen anderen Tag.'
      );
      return;
    }
    setStartDate(startDate);
    setEndDate(endDate);
    onChange({ startDate, endDate });
  };

  const onFocusChange = (focusedInput) => {
    // Force the focusedInput to always be truthy so that dates are always selectable
    setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
  };

  const renderCalendarInfo = () => {
    return (
      <CalendarInfo>
        <div>
          <small>
            * Abholungen sind{' '}
            <strong>nicht am Wochenende und an Feiertagen</strong> möglich.
          </small>
        </div>
        <div>
          <Button onClick={hideDatePicker}>Übernehmen</Button>
        </div>
      </CalendarInfo>
    );
  };

  const showDatePickerFunc = (input) => {
    setShowDatePicker(true);
    setFocusedInput(input || 'startDate');
    dispatch(actions.datePickerOpen(true));

    //Scroll to Datepicker
    if (window.innerWidth > 768) {
      const topOfDatepicker = ReactDOM.findDOMNode(
        datepickerRef.current
      ).getBoundingClientRect().top;
      if (window.pageYOffset < topOfDatepicker) {
        window.scroll(0, topOfDatepicker - 50);
      }
    }

    if (onOpen) {
      onOpen();
    }
  };

  const hideDatePicker = () => {
    dispatch(actions.datePickerOpen(false));
    setShowDatePicker(false);
    if (onClose) {
      onClose();
    }
  };

  const startDateString =
    startDateCurrent && startDateCurrent.format('DD.MM.YYYY');
  const endDateString = endDateCurrent && endDateCurrent.format('DD.MM.YYYY');

  return (
    <Media query={`(min-width: ${breakpoints.minTablet})`}>
      {(isDesktop) => (
        <DatePickerWrapper error={error} label={label}>
          {!!(label || error) && <Label error={error}>{error || label}</Label>}
          <InputWrapper>
            <TextField
              ref={datepickerRef}
              Icon={Calendar}
              focused={showDatePicker && focusedInput === 'startDate'}
              onChange={() => {}}
              onClick={() => showDatePickerFunc('startDate')}
              placeholder={'Abholung'}
              value={startDateString || ''}
              readOnly
            />
            <ArrowRight />
            <TextField
              Icon={Calendar}
              focused={showDatePicker && focusedInput === 'endDate'}
              onChange={() => {}}
              placeholder={'Rückgabe'}
              onClick={() => showDatePickerFunc('endDate')}
              value={endDateString || ''}
              readOnly
            />
          </InputWrapper>
          <MouseTooltip
            visible={showDatePicker}
            offsetX={15}
            offsetY={10}
            style={{ zIndex: 99 }}
          >
            <span
              style={{
                background: 'white',
                padding: '1rem',
                border: `1px solid ${colors.primary}`,
              }}
            >
              {focusedInput === 'endDate' ? 'Rückgabe 🏁' : 'Abholung 🚀'}
            </span>
          </MouseTooltip>
          {showDatePicker && (
            <DayPickerRangeController
              {...datePickerProps}
              onDatesChange={onDatesChange}
              orientation={isDesktop ? 'horizontal' : 'verticalScrollable'}
              verticalHeight={!isDesktop ? 0 : 800}
              numberOfMonths={!isDesktop ? 6 : 2}
              renderCalendarInfo={renderCalendarInfo}
              onFocusChange={onFocusChange}
              focusedInput={focusedInput}
              startDate={startDateCurrent}
              endDate={endDateCurrent}
              onOutsideClick={hideDatePicker}
              isDayBlocked={(date) => {
                let dateTime = moment(date);
                const weekday = dateTime.isoWeekday();

                return (
                  (focusedInput === 'startDate' &&
                    (weekday === 7 ||
                      weekday === 6 ||
                      DateHelper.isSundayOrHoliday(dateTime.toDate()))) ||
                  (focusedInput === 'endDate' &&
                    dateTime.isBefore(moment(startDateCurrent)))
                );
              }}
            />
          )}
        </DatePickerWrapper>
      )}
    </Media>
  );
}

function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

Datepicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  small: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
};

Datepicker.defaultProps = {
  // calendar presentation and interaction related props
  renderMonthText: null,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  isRTL: false,
  hideKeyboardShortcutsPanel: true,
  firstDayOfWeek: 1,
  orientation: 'horizontal',

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,

  // internationalization
  monthFormat: 'MMMM YYYY',
  phrases: DateRangePickerPhrases,

  // example props for the demo
  startDate: null,
  endDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,

  // day presentation and interaction related props
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,

  // calendar presentation and interaction related props
  verticalHeight: undefined,
  onOutsideClick() {},
  renderCalendarInfo: null,
  renderMonthElement: null,

  // navigation related props
};
