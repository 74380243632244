import React from 'react'
import { PropTypes } from 'prop-types'
import { TextField } from '../TextField'
import styled from 'styled-components'
import Button from '../Button/Button'
import { breakpoints } from '../theme/breakpoints'

const KilometerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 3rem;

  > button {
    width: 9.3rem;
    margin: 0.5rem 1rem 0.5rem 0;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    margin-top: 0rem;
  }
`

const TextFieldWrapper = styled.div`
  width: 16rem;
  margin-right: 2rem;
  
  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`

const KilometerSelector = ({ km, ...props }) => (
  <KilometerWrapper>
    <TextFieldWrapper>
      <TextField
        type="number"
        label="Benötigte Kilometer*"
        afterLabel="km"
        value={km}
        {...props}
      />
    </TextFieldWrapper>
    <ButtonWrapper>
      <Button onClick={() => props.onChange(250)}>250 km</Button>
      <Button onClick={() => props.onChange(500)}>500 km</Button>
      <Button onClick={() => props.onChange(750)}>750 km</Button>
      <Button onClick={() => props.onChange(1000)}>1000 km</Button>
      <Button onClick={() => props.onChange(1500)}>1500 km</Button>
    </ButtonWrapper>
  </KilometerWrapper>
)

KilometerSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  error: PropTypes.string,
}

export default KilometerSelector
