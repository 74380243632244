import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Adapter = props => (
  <SvgContainer>
    <Svg viewBox="0 0 1000 1000" {...props}>
      <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M6325.9,3865.4c-47.6-45.4-56.7-77.2-56.7-181.5V3559H4104.3c-2369.1,0-2385,0-2727.7-133.9c-381.2-147.5-757.9-458.4-971.2-803.3C-257.3,1557.5,201.1,171,1369.8-296.5c306.4-122.5,472-138.4,1463.7-138.4h894.1v-487.9c0-490.2,0-490.2,56.7-544.6l54.5-56.7h1397.9h1395.6v-306.3c0-297.3,2.3-310.9,56.7-363.1c52.2-54.5,65.8-56.7,363.1-56.7h306.4v-669.4v-671.7l56.7-54.5c72.6-74.9,177-74.9,249.6,0l56.7,54.5v671.7v669.4h363.1h363.1v-669.4v-671.7l56.7-54.5c72.6-74.9,177-74.9,249.6,0l56.7,54.5v671.7v669.4h306.3c297.3,0,310.9,2.3,363.1,56.7c54.5,52.2,56.7,65.8,56.7,363.1v306.3h124.8c104.4,0,136.2,9.1,181.5,56.7l56.7,54.5v1159.6V906.2l-56.7,54.5l-54.5,56.7h-2975h-2975l-54.5-56.7l-56.7-54.5V416.1V-74.1l-969,6.8l-971.2,9.1L1605.8,3.1c-263.2,86.2-451.6,206.5-658.1,413c-206.5,206.5-326.8,397.1-413,658.1c-52.2,161.1-59,217.9-59,487.9c0,270,6.8,326.8,59,487.9c86.2,261,206.5,451.6,413,658.1c206.5,206.5,394.9,324.5,658.1,415.3l181.5,61.3l2242.1,6.8l2239.8,6.8v-127.1c0-104.4,9.1-136.2,56.7-181.5l54.5-56.7H7540h1159.6l54.5,56.7c47.6,45.4,56.7,77.2,56.7,181.5v124.8h306.3c297.3,0,310.9,2.3,363.1,56.7c36.3,34,56.7,81.7,56.7,124.8c0,43.1-20.4,90.8-56.7,124.8c-52.2,54.5-65.8,56.7-363.1,56.7h-306.3v124.8c0,104.4-9.1,136.2-56.7,181.5l-54.5,56.7H7540H6380.4L6325.9,3865.4z M8447.7,3377.5v-181.5H7540h-907.7v181.5V3559H7540h907.7V3377.5z M9536.9-253.4v-907.7H6813.8H4090.6v907.7v907.7h2723.1h2723.1V-253.4z M9173.8-1705.7v-181.5H8084.6H6995.3v181.5v181.5h1089.3h1089.3V-1705.7z"/></g></g>
    </Svg>
  </SvgContainer>
)

Adapter.propTypes = {
  baseline: PropTypes.bool
}
