import React from 'react'
import { breakpoints } from '../theme/breakpoints'
import styled from 'styled-components'

const LayoutContentStyled = styled.main`
  flex: 1 1 auto;
  margin: 0 2rem;

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    margin: 0 4.2rem;
  }
`

export const LayoutContent = ({ children, ...props }) => (
  <LayoutContentStyled {...props}>{children}</LayoutContentStyled>
)
