import { createAction } from './util/createAction'

export const [
  createBooking,
  createBookingSuccess,
  createBookingFailure
] = createAction('BOOKING_CREATE', booking => ({
  booking
}))

export const [setBookingValue] = createAction(
  'BOOKING_SET_VALUE',
  (key, value) => ({
    key,
    value
  })
)
