import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import { breakpoints } from '../theme/breakpoints'
import { fonts } from '../theme/fonts'

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => (props.big ? fonts.subline : fonts.text)};
  font-size: ${props => (props.big ? '2.2rem' : '1.2rem')};
  background: ${colors.primary};
  padding: ${props =>
    props.small
      ? '0.5rem 1rem'
      : !!props.Icon
        ? '1.1rem 1rem 0.5rem 2rem'
        : props.big
          ? '1.5rem 2rem 1rem'
          : '1rem 2rem 1rem'};
  border: none;
  line-height: ${props => (props.big ? '2.25rem' : '1.5rem')};
  border-radius: ${props =>
    props.notRounded ? '0' : props.small ? '1rem' : '2rem'};
  color: ${colors.white};
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    border-radius: ${props => (props.big && !props.rounded ? '0' : '2rem')};
  }

  &:hover {
    background: ${colors.primaryLight};
  }

  svg {
    margin-top: -0.5rem;
    width: ${props => (props.big ? '2.5rem' : '2rem')};
    height: ${props => (props.big ? '2.5rem' : '2rem')};
  }
`
const ButtonText = styled.span`
  @media screen and (max-width: ${breakpoints.minTablet}) {
    display: inline;
  }
`

const Button = ({ children, onClick, Icon, ...props }) => (
  <ButtonStyled onClick={onClick} {...props} Icon={Icon}>
    <ButtonText>{children}</ButtonText>
    {!!Icon && <Icon />}
  </ButtonStyled>
)

Button.propTypes = {
  onClick: PropTypes.func,
  small: PropTypes.bool,
  notRounded: PropTypes.bool,
  rounded: PropTypes.bool,
  type: PropTypes.string,
  big: PropTypes.bool,
  Icon: PropTypes.func
}

Button.defaultProps = {
  type: 'button'
}

export default Button
