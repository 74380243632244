import React, { Component } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../theme/breakpoints'
import Karte from '../../images/gruppenbus_karte_anfahrt2.jpg'
import BackgroundImage from '../BackgroundImage/BackgroundImage'

const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Col = styled.div`
   min-height: 40rem;
   height: 60vh;
   max-height: 50rem;
   width: 50%; 
   padding: 3rem;
   
   @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%; 
  }
`

class GoogleMap extends Component {

  render() {
    return (
      <Table>
        <Col>
          {/*<iframe title="Gruppenbus Adresse"*/}
            {/*src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2425.887248674244!2d13.436698815808741!3d52.55356437982057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84df1d8c84021%3A0x6631030ec13bd972!2sGruppenbus!5e0!3m2!1sde!2sde!4v1547072475193"*/}
            {/*width="100%" height="100%" frameBorder="0" allowFullScreen />*/}
          <a rel="noopener noreferrer" href="https://www.google.de/maps/place/Gruppenbus/@52.5535644,13.4366988,17z/data=!3m1!4b1!4m5!3m4!1s0x47a84df1d8c84021:0x6631030ec13bd972!8m2!3d52.5535644!4d13.4388875" target="_blank"><BackgroundImage imgSrc={Karte} />
          Auf Google Maps ansehen</a>
        </Col>
        <Col>
          <strong>Gruppenbus Kleinbusvermietung</strong><br />
          Touralarm GmbH<br />
          Goethestraße 50 - 54<br />
          13086 Berlin (Weißensee)<br />
          <br />
          Tel.: <a rel="noopener noreferrer" href="tel:+493099271837" >030 / 992 71 837</a><br />
          E-Mail: <a rel="noopener noreferrer" href="mailto:berlin@gruppenbus.com" >berlin@gruppenbus.com</a><br />
          <br />
          <strong>Bürozeiten:</strong><br />
          Mo - Fr: 09:00 - 17:00 Uhr

        </Col>
      </Table>
    )
  }
}

export default GoogleMap