import { LOCATION_CHANGE } from 'connected-react-router';
import { spawn, take, takeEvery, call } from 'redux-saga/effects';
import { createAnzeigeClick } from '../config/api';
import * as actions from '../actions';

export default function* AppSaga() {
  yield spawn(scrollToTop);
  yield takeEvery(actions.createAnzeigeClick, createAnzeigeClickRequest);
}

function* scrollToTop() {
  let lastPathname;
  while (true) {
    const {
      payload: {
        location: { pathname },
      },
    } = yield take(LOCATION_CHANGE);
    if (pathname !== lastPathname) {
      setTimeout(() => window.scrollTo(0, 0), 100);
      lastPathname = pathname;
    }
  }
}

function* createAnzeigeClickRequest({ payload: { anzeige } }) {
  yield call(createAnzeigeClick, anzeige);
}
