import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from '../Image/Image'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import uniqueId from 'lodash/uniqueId'

const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  .slick-slide {
    height: calc(100% - 5px)
  }
  
  .slick-dots {
    bottom: 2rem;
  }

  .slick-next {
    right: 5px;
    z-index: 5;
  }

  .slick-prev {
    left: 5px;
    z-index: 5;
  }

  .slick-dots li {
    width: 1rem;
  }

  .slick-dots li button:before {
    color: ${colors.white};
    opacity: 0.55;
    line-height: 21px;
  }

  .slick-dots li.slick-active button:before {
    color: ${colors.white};
    font-size: 9px;
    opacity: 0.85;
  }
`

export default class ImageSlider extends React.Component {

  constructor(props) {
    super(props);

    this.preventTouch = this.preventTouch.bind(this);
    this.touchStart = this.touchStart.bind(this);

    this.id = uniqueId()
  }

  componentDidMount() {
    // Disable touchmove to prevent scrolling entire page
    const carousel = document.getElementById('slider' + this.id); // Your site element containing react-slick's carousel-container
    carousel.addEventListener('touchstart', this.touchStart);
    carousel.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  touchStart(e) {
    // capture user's starting finger position, for later comparison
    this.firstClientX = e.touches[0].clientX;
  }

  preventTouch(e) {
    // only prevent touch on horizontal scroll (for horizontal carousel)
    // this allows the users to scroll vertically past the carousel when touching the carousel
    // this also stabilizes the horizontal scroll somewhat, decreasing vertical scroll while horizontal scrolling
    const clientX = e.touches[0].clientX - this.firstClientX;
    const horizontalScroll = Math.abs(clientX) > 5;
    if (horizontalScroll) {
      e.preventDefault();
    }
  }


  render() {
    const { images } = this.props
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      lazyLoad: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchTreshold: 5,
      adaptiveHeight: false
    }
    return (
      <SliderWrapper id={'slider' + this.id}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <Image imgSrc={image} alt="" />
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    )
  }
}

ImageSlider.propTypes = {
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
}
