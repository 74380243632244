import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../theme/colors';
import { breakpoints } from '../theme/breakpoints';

const TextFieldStyled = styled.span`
  position: relative;
  min-width: 4rem;
  width: 100%;

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    position: absolute;
    left: 1.6rem;
    top: 53%;
    transform: translateY(-50%);
    pointer-events: none;
    fill: ${colors.darkGrey};
  }

  .icon svg {
    width: 1.7rem;
    height: 1.7rem;
  }

  .icon + input {
    padding-left: 4.2rem;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.dark};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.dark};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.dark};
  }

  .disabled input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.lightGrey};
    opacity: 1; /* Firefox */
  }
  .disabled input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.lightGrey};
  }

  .disabled input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.lightGrey};
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  line-height: 1.42rem;
  min-height: 2rem;

  color: ${props =>
    !!props.error ? colors.red : props.disabled ? colors.lightGrey : 'inherit'};

  pointer-events: ${props => (!!props.floatingLabel ? 'none' : 'auto')};

  transform: ${props =>
    !!props.floatingLabel ? 'translate(1.9rem, 4.1rem) scale(1.15)' : 'none'};
  transform-origin: 0 100%;

  transition: ${props => (!!props.floatingLabel ? '150ms transform' : 'none')};

  &:focus-within {
    color: ${props => (!!props.floatingLabel ? 'inherit' : 'auto')};
    font-size: 1.4rem;
    transform: translate(0, 0) scale(1);
  }
`;

const Input = styled.input`
  // appearance: none;
  font-family: inherit;
  color: ${colors.semiBlack};
  background-color: transparent;

  min-height: 4rem;
  width: 100%;
  margin: 0;
  padding: ${props =>
    props.hasAfterLabel ? '1rem 4.9rem 1rem 1.9rem' : '1rem 1.9rem 1rem'};

  font-size: 1.6rem;
  border: 1px solid
    ${props =>
      !!props.error
        ? colors.red
        : props.focused
        ? colors.black
        : colors.silver};
  box-shadow: ${props =>
    props.focused ? '0 0 10px 5px ' + colors.primaryLighter : 'none'};
  border-radius: 3px;

  @media screen and (max-width: ${breakpoints.minPhone}) {
    font-size: 1.4rem;
    padding-right: 0.5rem;
  }
`;

const Textarea = styled.textarea`
  // appearance: none;
  font-family: inherit;
  color: ${colors.semiBlack};
  background-color: transparent;

  min-height: 15rem;
  width: 100%;
  margin: 0;
  padding: ${props =>
    props.hasAfterLabel ? '1rem 4.9rem 1rem 1.9rem' : '1rem 1.9rem 1rem'};

  font-size: 1.6rem;
  border: 1px solid
    ${props =>
      !!props.error
        ? colors.red
        : props.focused
        ? colors.black
        : colors.silver};
  box-shadow: ${props =>
    props.focused ? '0 0 10px 5px ' + colors.primaryLighter : 'none'};
  border-radius: 3px;

  @media screen and (max-width: ${breakpoints.minPhone}) {
    font-size: 1.4rem;
    padding-right: 0.5rem;
  }
`;

const After = styled.span`
  margin-left: -4rem;
  color: ${colors.silver};
`;

/** Stateful react component class, to increase reusability
 *  of TextField e.g. in the DatePicker component.
 */
export class TextField extends React.Component {
  render() {
    const {
      children,
      htmlId,
      name,
      label,
      error,
      floatingLabel,
      placeholder,
      disabled,
      Icon,
      afterLabel,
      onChange,
      value,
      ...props
    } = this.props;
    const inputProps = {
      id: htmlId || name,
      name,
      type: 'text',
      placeholder: floatingLabel ? undefined : placeholder,
      disabled,
      value,
      error: error,
      hasAfterLabel: !!afterLabel,
      onChange: e => onChange(e.target.value),
      ...props
    };

    return (
      <TextFieldStyled>
        {!!(label || error) && (
          <Label htmlFor={htmlId || name} error={error}>
            {error || label}
          </Label>
        )}
        <div className="input-wrapper">
          {!!Icon && (
            <div className="icon">
              <Icon />
            </div>
          )}

          {inputProps.type === 'textarea' ? (
            <Textarea {...inputProps} />
          ) : (
            <Input {...inputProps} />
          )}
          <After>{afterLabel}</After>
        </div>
        {children}
      </TextFieldStyled>
    );
  }
}

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  floatingLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  Icon: PropTypes.func,
  htmlId: PropTypes.string,
  afterLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextField.defaultProps = {
  type: 'text'
};
