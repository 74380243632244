import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

const ButtonStyled = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  
  &:hover {
    text-decoration: underline;
  }
`

const ButtonPlain = ({ children, onClick, ...props }) => (
  <ButtonStyled onClick={onClick} {...props} >{children}</ButtonStyled>
)

ButtonPlain.propTypes = {
  onClick: PropTypes.func,
}

ButtonPlain.defaultProps = {
  type: 'button'
}

export default ButtonPlain
