import * as actions from '../actions';
import { createReducer } from './util/createReducer';
import moment from 'moment';

const initialState = {
  store: {
    startDate: moment().add(
      moment().isoWeekday() === 6 ? 6 : moment().isoWeekday() === 7 ? 8 : 7,
      'days'
    ),
    endDate: moment().add(
      moment().isoWeekday() === 6 ? 13 : moment().isoWeekday() === 7 ? 15 : 14,
      'days'
    ),
    startTime: '09:00',
    endTime: '09:00',
    bus: null,
    km: 100,
    versicherung: false,
    navi: false,
    schneeketten: false,
    wechselrichter: false,
    kindersitz: false,
    laderampe: false,
    Rollstuhlhaltesystem: false,
    vname: '',
    nname: '',
    email: '',
    tel: '',
    lander: '',
    strasse: '',
    plz: '',
    ort: '',
    firma: '',
    kommentar: '',
  },
};
export default createReducer(initialState, {
  [actions.setBookingValue]: (state, { payload: { key, value } }) => ({
    ...state,
    store: { ...state.store, [key]: value },
  }),
  [actions.createBookingSuccess]: (state) => ({
    ...state,
    store: { ...initialState.store, bus: state.store.bus },
  }),
});
