import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Case = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 456 456" {...props}>
      <g>
        <path
          d="M332,88h-40V24c0-13.233-10.767-24-24-24h-80c-13.233,0-24,10.767-24,24v64h-40c-22.056,0-40,17.944-40,40v256
		c0,19.317,13.764,35.479,32,39.195V436c0,11.028,8.972,20,20,20s20-8.972,20-20v-12h144v12c0,11.028,8.972,20,20,20s20-8.972,20-20
		v-12.805c18.235-3.717,32-19.878,32-39.195V128C372,105.944,354.056,88,332,88z M188,16h80c4.411,0,8,3.589,8,8h-96
		C180,19.589,183.589,16,188,16z M180,40h96v48h-96V40z M140,436c0,2.168-1.832,4-4,4s-4-1.832-4-4v-12h8V436z M320,440
		c-2.168,0-4-1.832-4-4v-12h8v12C324,438.168,322.168,440,320,440z M356,384c0,10.429-6.689,19.321-16,22.624V224
		c0-4.418-3.582-8-8-8s-8,3.582-8,8v184H132V208.471c0-9.082,7.389-16.471,16.471-16.471H268c4.418,0,8-3.582,8-8s-3.582-8-8-8
		H148.471C130.566,176,116,190.566,116,208.471v198.153c-9.311-3.302-16-12.195-16-22.624V128c0-13.234,10.766-24,24-24h208
		c13.233,0,24,10.766,24,24V384z"
        />
        <path
          d="M307.529,176H300c-4.418,0-8,3.582-8,8s3.582,8,8,8h7.529c4.931,0,9.573,2.213,12.737,6.072
		c1.581,1.929,3.877,2.928,6.191,2.928c1.784,0,3.58-0.594,5.067-1.813c3.417-2.801,3.916-7.842,1.114-11.259
		C326.425,180.348,317.272,176,307.529,176z"
        />
      </g>
    </Svg>
  </SvgContainer>
)

Case.propTypes = {
  baseline: PropTypes.bool
}
