import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import Image from "../Image/Image";
import logoSrc from "./LogoGruppenbusGrossSubline.png";
import { breakpoints } from "../theme/breakpoints";
import { history } from "../../config/store";

const LogoStyled = styled.div`
  max-width: 190px;
  margin-top: 20px;
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    max-width: 270px;
    margin-top: 8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-15px);
  }

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    max-width: 350px;
  }
`;

const Logo = ({ logoSrc, alt, ...props }) => (
  <LogoStyled>
    <Image imgSrc={logoSrc} alt={alt} {...props} />
  </LogoStyled>
);

Logo.propTypes = {
  logoSrc: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func
};

Logo.defaultProps = {
  logoSrc: logoSrc,
  alt: "Gruppenbus",
  onClick: () => {
    history.push("/");
  }
};

export default Logo;
