import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => props.baseline ? '-1.1rem' : '0'};
`

export const Add = (props) => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd" strokeWidth={1.5} stroke="currentColor">
        <path d="M6 10h8m-4 4V6" />
        <circle cx={10} cy={10} r={7.25} />
      </g>
    </Svg>
  </SvgContainer>
)

Add.propTypes = {
  baseline: PropTypes.bool
}
