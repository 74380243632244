import React from 'react'
import styled from 'styled-components'
import { zIndexes } from '../theme/zIndexes'
import { colors } from '../theme/colors'
import PropTypes from 'prop-types'
import { breakpoints } from '../theme/breakpoints'

const Header = styled.header`
  position: absolute;
  width: 100%;
  max-height: 8rem;
  height: 8rem;
  z-index: ${zIndexes.header};
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 1rem 2.2rem 0;
  background: ${colors.white};
  flex-direction: ${props => (props.large ? 'column' : 'row')};

  @media screen and (min-width: ${breakpoints.minTablet}) {
    padding: 3rem 4.2rem 3rem;
  }
`

export const LayoutHeader = ({ children, ...props }) => (
  <Header {...props}>{children}</Header>
)

LayoutHeader.propTypes = {
  large: PropTypes.bool
}
