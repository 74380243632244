import React from 'react';
import Mountain from '../../images/mountain.jpg';
import { LayoutContent, LayoutSection } from "../../ui";
import Stage from '../../ui/Stage/Stage';
import { breakpoints } from '../../ui/theme/breakpoints';
import styled, { keyframes } from 'styled-components';
import { H2 } from '../../ui';
import { colors } from '../../ui/theme/colors';
import Stepper from '../../ui/Stepper/Stepper';
import { history } from '../../config/store';
import Image from '../../ui/Image/Image';
import { ChevronR } from '../../ui/Icon';
import Button from '../../ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';

const grow = keyframes`
  60% {
    transform: scale(.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }

  100% {
    transform: scale(.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: ${colors.primary};
  }
`;

const show = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const draw = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const ConfirmationAnimation = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;

  circle {
    fill-opacity: 0;
    stroke: ${colors.primary};
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: ${grow} 1s 0.6s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }

  path {
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: ${draw} 0.5s 0.6s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }
`;

const Title = styled(H2)`
  font-size: 4rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const BookingDetails = styled.div`
  opacity: 0;
  animation: ${show} 1s 1.2s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  background: ${colors.lightGrey};
  border-radius: 1rem;
  padding: 2rem;
  max-width: 51rem;
  text-align: center;
  margin-top: 3rem;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      padding: 2rem;
    }
  }

  h2 {
    margin-bottom: 2rem;

    @media screen and (max-width: ${breakpoints.minTablet}) {
      padding: 1rem;
    }
  }
`;

const BookingDetailsData = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const ImageStyle = styled.div`
  flex: 0 0 100%;
  width: 100%;
  text-align: center !important;
`;

const ButtonStart = styled(Button)`
  margin: 0 auto;
  margin-top: 2rem;
`;

export function Successpage() {
  const booking = useSelector((store) => store.Booking.store);
  const busses = useSelector((store) => store.Bus.store);
  const dispatch = useDispatch();
  const firstRun = React.useRef(true);

  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      if (
        !booking.bus ||
        !booking.startDate ||
        !booking.endDate ||
        !booking.vname ||
        !booking.nname ||
        !booking.email ||
        !booking.tel
      ) {
        history.push('/');
      } else {
        if (window.location.hostname !== 'localhost' && window.gtag) {
          // Conversion for Google Adwords
          window.gtag('event', 'conversion', {
            send_to: 'AW-998982889/kX_iCNK65WwQ6Ymt3AM',
            value: busses[booking.bus].price || 120,
            currency: 'EUR',
            event_category: 'Buchung',
            event_label: 'Buchungsanfrage',
          });
          // Conversion for Google Analytics 4
          window.gtag('event', 'purchase', {
            transaction_id: booking.id,
            value: busses[booking.bus].price || 120,
            currency: 'EUR',
            items: [
              {
                item_id: busses[booking.bus].id,
                item_name: busses[booking.bus].name,
              },
            ],
          });
        }
        dispatch(actions.createBooking(booking));
      }
    }
  }, [booking, busses, dispatch]);

  return (
    <div>
      <Stage
        imgSrc={Mountain}
        alt={'Gruppenbus'}
        slogan={'Wir melden uns!'}
        small
      />
      <LayoutContent>
        <LayoutSection centered>
          <Stepper step={5} />
          <br />
          <Title>Buchung erfolgreich übermittelt!</Title>
          <br />
          <ConfirmationAnimation>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="100"
              width="100"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <circle fill={colors.primary} cx="24" cy="24" r="22" />
              <path
                fill="none"
                stroke="#FFF"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M14 27l5.917 4.917L34 17"
              />
            </svg>
          </ConfirmationAnimation>
          <br />
          <br />

          <BookingDetails>
            <H2 center>Wie geht's weiter?</H2>
            <ImageStyle>
              <Image
                imgSrc={busses[booking.bus].img}
                alt={'Gebuchter Bus'}
                maxWidth={'200px'}
              />
            </ImageStyle>
            <BookingDetailsData>
              Wir prüfen die Buchung und die Verfügbarkeit der Fahrzeuge und
              melden uns innerhalb von 48h zurück. Erst nach Bestätigung der
              Buchung, ist diese verbindlich.
              <br />
              <br />
              Dein Gruppenbus Team
            </BookingDetailsData>
            <ButtonStart
              type="button"
              big
              rounded
              Icon={ChevronR}
              onClick={() => history.push('/')}
            >
              Zur Startseite
            </ButtonStart>
          </BookingDetails>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
