import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../ui/theme/colors';
import Checkbox from '../../ui/Checkbox/Checkbox';
import { fonts } from '../../ui/theme/fonts';
import { useSelector } from 'react-redux';

const ExtraOptionStyled = styled.label`
  width: 100%;
  min-width: 28rem;
  cursor: pointer;
  padding: 2rem;
  border: 1px solid ${colors.lightSilver};
  display: flex;
  border-radius: 1rem;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    border: 1px solid ${colors.primaryLight};
  }
`;

const Title = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const TextWrapper = styled.span`
  margin: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const Price = styled.span`
  color: ${colors.primary};
  font-family: ${fonts.subline};
  font-size: 1.8rem;
  margin-top: -0.1rem;
  margin-right: 1rem;
`;

const Description = styled.p``;

const IconWrapper = styled.div`
  background: ${colors.darkGrey};
  border-radius: 50%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colors.white};
  }
`;

const ExtraOption = ({ Icon, title, name, description, price, onChange }) => {
  const booking = useSelector((store) => store.Booking.store);
  return (
    <ExtraOptionStyled htmlFor={name}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Price>{price}</Price>

        {description && <Description>{description}</Description>}
      </TextWrapper>
      <Checkbox
        value="1"
        name={name}
        id={name}
        onChange={(e) => onChange(e.target.checked)}
        checked={booking[name] ? 'checked' : ''}
      />
    </ExtraOptionStyled>
  );
};

ExtraOption.propTypes = {
  Icon: PropTypes.func,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
};

export default ExtraOption;
