import { LOCATION_CHANGE } from 'connected-react-router'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import { requestPrice } from '../config/api'

export default function* BusSagas() {
  yield takeEvery(LOCATION_CHANGE, () => {})
  yield takeEvery(actions.calculateAllPrices, calculateAllPrices)
  yield takeEvery(actions.calculatePrice, calculatePrice)
}

function* calculateAllPrices({ payload: { bookingData } }) {
  const busses = yield select(store => Object.values(store.Bus.store))
  yield all(
    busses.map(bus =>
      put(actions.calculatePrice({ ...bookingData, bus: bus.id }))
    )
  )
}

function* calculatePrice({ payload: { booking } }) {
  if (booking.startDate && booking.endDate && booking.bus) {
    const result = yield call(requestPrice, booking)
    yield put(actions.calculatePriceSuccess(booking.bus, result.data))
  } else {
    yield put(actions.calculatePriceFailure(new Error('Kein Bus gewählt')))
  }
}
