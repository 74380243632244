import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { breakpoints } from '../theme/breakpoints'
import omit from 'lodash/omit'
import { fonts } from '../theme/fonts'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, omit(props,['noSpaceAfter']), children)
)`
  font-family: ${fonts.subline};
  font-size: 2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: ${props => (!props.noSpaceAfter ? '0.8rem' : '0')};

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    font-size: 2.4rem;
  }
`

export const H3 = ({ children, ...props }) => (
  <TagName {...props}>{children}</TagName>
)

H3.propTypes = {
  noSpaceAfter: PropTypes.bool
}

H3.defaultProps = {
  tag: 'h3'
}
