import React from "react";
// Concerts a hexadecimal color to rgba()
// thanks to https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const rgba = (hex, alpha) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
  throw new Error(`Bad Hex: "${hex}"`)
}

/* Custom PropTypes validation to only allow children of a certain type */
/**
 *  To allow the Wrapping of required components, a wrapper component can
 * expose `rendersTo` to signal that it will render the correct component:
 *
 * MyTableRowWrapper.rendersTo = TableRow
 *
 * */
export const childrenOfType = (...allowedComponentTypes) => {
  const allowedComponentTypeNames = allowedComponentTypes.map(
    ({ displayName, name }) => displayName || name
  )
  return (props, propName, componentName) => {
    componentName = componentName || 'ANONYMOUS'

    if (props[propName]) {
      let children = props[propName]

      const invalidChild = React.Children.toArray(children).find(child => {
        if (!child || !child.type) {
          return true
        }

        /* Check either Component.rendersTo or the Component itself */
        const rendersTo = child.type.rendersTo || child.type

        return (
          allowedComponentTypeNames.indexOf(
            rendersTo.displayName || rendersTo.name
          ) === -1
        )
      })

      if (invalidChild) {
        return new Error(
          `${propName} in ${componentName} can only be of type "${allowedComponentTypeNames.join(
            '", "'
          )}"`
        )
      }
    }

    // assume all ok
    return null
  }
}
