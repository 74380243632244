import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import { H2, Paragraph } from '../Typography'
import ImageSlider from '../ImageSlider/ImageSlider'

const Card = styled.div`
  padding: 1.15rem 1.15rem 2rem;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  box-shadow: 2px 4px 10px ${colors.silver};
  margin-bottom: 3rem;
  background-color: ${colors.white};
  
  img {
    border-radius: 5px;
  }
  
  p {
    color: ${colors.darkGrey};
  }
`

const Category = styled.div`
  padding: .5rem 3rem;
  display: inline-block;
  color: ${colors.primary};
  border: 1px solid #F2F2F2;
  box-shadow: 2px 2px 6px ${colors.silver};
  background-color: ${colors.white};
  border-radius: 5px;
  margin-bottom: 1rem;
  transform: translateY(-50%);
  text-transform: uppercase;
`

const Title = styled(H2)`
  margin-bottom: 1.5rem;
`

const BusOverviewCard = ({ images, alt, category, title, description }) => (
  <Card>
    <ImageSlider images={images}/>
    <Category>{category}</Category>
    <Title center>{title}</Title>
    <Paragraph>{description}</Paragraph>
  </Card>
)

BusOverviewCard.propTypes = {
  images: PropTypes.array.isRequired,
  alt: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default BusOverviewCard
