import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Phone = props => (
  <SvgContainer>
    <Svg width={1000} height={1000} viewBox="0 0 1000 1000" {...props}>
      <g>
        <path d="M721.9,10H284.3c-37,0-80.1,24.7-80.1,55.5v856.7c0,37,43.1,67.8,80.1,67.8h437.6c37,0,74-30.8,74-67.8V71.6C795.8,34.7,758.9,10,721.9,10z M450.7,77.8h104.8v24.7H450.7V77.8z M500,953c-24.7,0-49.3-18.5-49.3-49.3c0-24.7,18.5-49.3,49.3-49.3c24.7,0,49.3,18.5,49.3,49.3C549.3,934.5,530.8,953,500,953z M728.1,817.4H278.1V170.3h449.9V817.4z" />
      </g>
    </Svg>
  </SvgContainer>
)

Phone.propTypes = {
  baseline: PropTypes.bool
}
