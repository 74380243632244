import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../Button/Button'

const OverlayContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height:  auto;
  z-index: 99;
  background: white;
  width: 100%;
  padding: 1rem 2rem;
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0.9;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
`

function saveCookie() {
  document.cookie = 'hidecookieAsk=1;path=/'
}

const CookieOverlay = () => {
  const initialState =
    document.cookie && document.cookie.indexOf('hidecookieAsk=1') >= 0
  const [hasCookie, setCookieStatus] = useState(initialState)
  return hasCookie ? null : (
    <OverlayContainer>
      <div>
        Unsere Webseite verwendet Cookies. <a rel="noopener noreferrer" href="/impressum">Zur Datenschutzbestimmung geht's hier lang.</a>
      </div>
      <Container>
        <Button
          onClick={() => {
            saveCookie()
            setCookieStatus(true)
          }}
        >
          Alles klar!
        </Button>
      </Container>
    </OverlayContainer>
  )
}

export default CookieOverlay
