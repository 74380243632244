import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import { LayoutContent, LayoutSection } from '../../ui/Layout'
import FooterGrunchBackground from './grunge_footer_top.png'
import Image from '../Image/Image'
import { zIndexes } from '../theme/zIndexes'

const FooterStyled = styled.footer`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 4rem;

  color: ${colors.white};
  background: ${colors.semiBlack};
`

const FooterGrunge = styled.div`
  position: absolute;
  z-index: ${zIndexes.header};
  width: 100%;
  top: 0;
  transform: translateY(-65%);
`

const FooterGrungeInner = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: ${(90 / 1700) * 100}%;
    width: 100%;
  }

  > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const StyledLayoutSection = styled(LayoutSection)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export const Footer = ({ children, ...props }) => (
  <FooterStyled {...props}>
    <FooterGrunge>
      <FooterGrungeInner>
        <Image
          imgSrc={FooterGrunchBackground}
          alt={'footer grunch background'}
        />
      </FooterGrungeInner>
    </FooterGrunge>
    <LayoutContent>
      <StyledLayoutSection>{children}</StyledLayoutSection>
    </LayoutContent>
  </FooterStyled>
)
