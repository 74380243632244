/**
 * @param {T} initialState
 * @param {{[key:string]: (state: T, action: any) => T}} reducerObj
 * @template T
 */
export function createReducer(initialState, reducerObj) {
  return (state = initialState, action) => {
    if (reducerObj[action.type]) {
      return reducerObj[action.type](state, action);
    }
    return state;
  };
}
