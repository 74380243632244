import * as React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { breakpoints } from '../theme/breakpoints'

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    overflow: ${props => (props.noScroll ? 'hidden' : 'visible')};
  }
`


export const Layout = ({ children, ...props }) => (
  <LayoutStyled {...props} >{children}</LayoutStyled>
)

Layout.propTypes = {
  noScroll: PropTypes.bool
}
