import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const CarSeat = props => (
  <SvgContainer>
    <Svg viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <g>
            <path
              d="M339.605,198.072c-2.458-10.47-10.377-18.654-20.668-21.359c-10.726-2.816-28.928-6.187-54.844-6.187
				c-3.046,0-5.009,0.06-5.393,0.077l-2.372,0.094l-2.884-0.094c-0.384-0.017-2.389-0.077-5.564-0.077
				c-25.907,0-44.117,3.371-54.835,6.187c-10.3,2.705-18.219,10.889-20.676,21.35l-25.003,106.291
				c-1.186,4.992-1.399,9.916-0.657,14.635c0.427,2.714,0.896,5.308,1.391,7.774c2.91,14.473,16.572,24.021,31.087,22.502v-25.003
				c0-4.719,3.814-8.533,8.533-8.533s8.533,3.814,8.533,8.533v22.212c5.734-0.939,11.418-1.732,17.067-2.415V315.73
				c0-4.719,3.814-8.533,8.533-8.533c4.719,0,8.533,3.814,8.533,8.533v26.684c5.751-0.427,11.435-0.708,17.067-0.853v-34.364
				c0-4.719,3.814-8.533,8.533-8.533c4.719,0,8.533,3.814,8.533,8.533v34.364c5.632,0.145,11.315,0.427,17.067,0.853V315.73
				c0-4.719,3.814-8.533,8.533-8.533s8.533,3.814,8.533,8.533v28.331c5.658,0.683,11.341,1.468,17.067,2.406v-22.204
				c0-4.719,3.814-8.533,8.533-8.533s8.533,3.814,8.533,8.533v25.003c14.49,1.51,28.177-8.03,31.095-22.502
				c0.486-2.458,0.956-5.043,1.391-7.757c0.742-4.727,0.512-9.66-0.657-14.652L339.605,198.072z"
            />
            <path
              d="M432.707,329.643c2.125-13.508,1.57-27.281-1.638-40.926l-30.396-129.118c-9.54-30.635-43.708-45.005-76.416-51.686
				V85.333h24.004c8.986,0,17.391-4.412,22.511-11.793c5.112-7.373,6.272-16.802,3.123-25.19l-11.469-30.592
				C358.441,7.134,348.142,0,336.801,0H175.18c-11.341,0-21.641,7.134-25.634,17.766l-11.469,30.575
				c-3.149,8.397-1.98,17.826,3.132,25.199c5.111,7.381,13.525,11.793,22.502,11.793h24.013v22.665
				c-32.802,6.767-66.97,21.282-76.578,52.173L80.903,288.717c-3.209,13.645-3.755,27.418-1.621,40.934
				c9.873,62.558,36.267,97.015,74.308,97.015h1.502l1.408-0.512c0.452-0.162,46.285-16.555,99.49-16.555
				c53.197,0,99.029,16.393,99.49,16.555l1.408,0.512h1.502C396.432,426.667,422.825,392.209,432.707,329.643z M204.79,85.333h102.4
				v19.712c-26.684-3.593-48.99-2.731-50.816-2.637c-2.159-0.111-24.721-0.973-51.584,2.688V85.333z M382.13,321.673
				c-0.478,2.97-0.973,5.786-1.519,8.457c-4.335,21.572-23.458,36.514-44.8,36.514c-2.978,0-6.007-0.299-9.045-0.905
				c-24.397-4.872-48.205-7.339-70.775-7.339s-46.379,2.466-70.767,7.339c-24.781,4.984-48.922-11.034-53.862-35.627
				c-0.538-2.679-1.041-5.487-1.502-8.456c-1.092-6.912-0.794-14.054,0.888-21.214l25.011-106.283
				c3.908-16.631,16.529-29.636,32.947-33.946c11.682-3.081,31.445-6.75,59.179-6.75c3.541,0,5.777,0.077,6.246,0.094l2.219,0.094
				l1.587-0.094c0.282-0.017,2.551-0.094,6.161-0.094c27.733,0,47.488,3.669,59.187,6.75c16.401,4.309,29.03,17.314,32.939,33.954
				l25.011,106.274C382.915,307.601,383.214,314.743,382.13,321.673z"
            />
            <path
              d="M74.744,379.962c-6.187,2.278-12.493,5.658-17.877,10.761c-9.429,8.917-14.208,21.009-14.191,36.42
				c0.034,0.819,5.623,80.205,102.383,84.642v-68.582C123.077,440.779,93.27,427.757,74.744,379.962z"
            />
            <path
              d="M255.989,426.664c-49.681,0-93.278,15.394-93.705,15.548l-0.162,0.06v69.726h187.733v-69.734l-0.171-0.06
				C349.266,442.058,305.67,426.664,255.989,426.664z"
            />
            <path
              d="M455.114,390.723c-5.393-5.103-11.699-8.491-17.877-10.761c-18.534,47.795-48.333,60.817-70.315,63.241v68.582
				c96.751-4.437,102.34-83.831,102.4-85.12C469.322,411.732,464.535,399.64,455.114,390.723z"
            />
          </g>
        </g>
      </g>
    </Svg>
  </SvgContainer>
)

CarSeat.propTypes = {
  baseline: PropTypes.bool
}
