import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { H1, H3, Paragraph } from '../Typography'
import { breakpoints } from '../theme/breakpoints'
import imageBorder from './grunge_border.png'
import imageBorderFull from './grunge_border_full.png'
import Button from '../Button/Button'
import {history} from '../../config/store'
import { colors } from '../theme/colors'

const TextImageWrapper = styled.div`
  margin-bottom: 5rem;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    display: flex;
  }

  > div {
    overflow: hidden;

    @media screen and (min-width: ${breakpoints.minTablet}) {
      width: 50%;
    }

    &:nth-child(2) {
      padding-left: 3.2rem;
      padding-right: 3.2rem;

      @media screen and (min-width: ${breakpoints.minTablet}) {
        padding-left: 6rem;
      }
    }
  }
`

const BackgroundImage = styled.div`
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  position: relative;
  background-position-x: ${props => props.bgPosX};
  background-position-y: ${props => props.bgPosY};
  min-height: 200px;
`

const BackgroundImageGrunge = styled.div`
  background: url(${imageBorderFull}) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    background-image: url(${imageBorder});
  }
`

const TextWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  min-height: 350px;
  max-width: 65rem;
`

const Title = styled(H1)`
  color: ${colors.silver};
`

const Subtitle = styled(H3)`
  margin-bottom: 3rem;
`

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`

const TextImage = ({
  image,
  bgPosX,
  bgPosY,
  alt,
  title,
  subtitle,
  text,
  linkLabel,
  target
}) => (
  <TextImageWrapper>
    <BackgroundImage imgSrc={image} bgPosX={bgPosX} bgPosY={bgPosY}>
      <BackgroundImageGrunge />
    </BackgroundImage>
    <TextWrapper>
      <Title tag={'h2'} noSpaceAfter={!!subtitle}>
        {title}
      </Title>
      <Subtitle tag={'h3'}>{subtitle}</Subtitle>
      <Paragraph>{text}</Paragraph>
      {target ? (
        <ButtonWrapper>
          <Button big rounded onClick={() => history.push(target)}>
            {linkLabel}
          </Button>
        </ButtonWrapper>
      ) : (
        false
      )}
    </TextWrapper>
  </TextImageWrapper>
)

TextImage.propTypes = {
  image: PropTypes.string.isRequired,
  bgPosX: PropTypes.string,
  bgPosY: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkLabel: PropTypes.string,
  target: PropTypes.string
}

TextImage.defaultProps = {
  bgPosX: 'center',
  bgPosY: 'center'
}

export default TextImage
