import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { breakpoints } from '../theme/breakpoints'
import omit from 'lodash/omit'
import { fonts } from '../theme/fonts'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, omit(props, ['noSpaceAfter']), children)
)`
  font-family: ${fonts.subline};
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: ${props => (!props.noSpaceAfter ? '0.8rem' : '0')};

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    font-size: 1.8rem;
  }
`

export const H4 = ({ children, ...props }) => (
  <TagName {...props}>{children}</TagName>
)

H4.propTypes = {
  noSpaceAfter: PropTypes.bool
}

H4.defaultProps = {
  tag: 'h4'
}
