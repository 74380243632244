import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors } from '../theme/colors'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
  background: ${colors.lightSilver};
  border-radius: 50%;
  padding: 1rem;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Error = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 650 650" {...props}>
      <g>
        <path d="M987.5,784.6l2.3-2.4c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0l-2.2,2.3l-2.2-2.3c-0.3-0.4-0.9-0.4-1.3,0c-0.4,0.3-0.4,0.9,0,1.3l2.3,2.4l-2.3,2.4c-0.3,0.4-0.3,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.7-0.3l2.2-2.3l2.2,2.3c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.6-0.3c0.4-0.3,0.4-0.9,0-1.3L987.5,784.6z" />
        <path d="M962.3,788.3l2.2-2.3l2.2,2.3c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.6-0.3c0.4-0.3,0.4-0.9,0-1.3l-2.3-2.4l2.3-2.4c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0l-2.2,2.3l-2.2-2.3c-0.3-0.4-0.9-0.4-1.3,0c-0.4,0.3-0.4,0.9,0,1.3l2.3,2.4L961,787c-0.3,0.4-0.3,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3C961.9,788.6,962.2,788.5,962.3,788.3z" />
        <path d="M989.1,799.1c-12.7-8.1-22.7-2.5-27-0.1c-0.4,0.2-0.6,0.8-0.3,1.2c0.2,0.4,0.8,0.6,1.2,0.3c4-2.2,13.3-7.5,25.1,0.1c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.2,0.8-0.4C989.7,799.9,989.5,799.4,989.1,799.1z" />
        <path d="M564.6,281.5l47.7-50.2c7.2-7.6,6.9-19.6-0.7-26.8c-7.6-7.2-19.6-6.9-26.8,0.7l-46.4,48.8L492,205.1c-7.2-7.6-19.2-7.9-26.8-0.7c-7.6,7.2-7.9,19.2-0.7,26.8l47.7,50.2l-47.7,50.2c-7.2,7.6-6.9,19.6,0.7,26.8c3.7,3.5,8.4,5.2,13.1,5.2c5,0,10-2,13.8-5.9l46.4-48.8l46.4,48.8c3.7,3.9,8.8,5.9,13.8,5.9c4.7,0,9.4-1.7,13.1-5.2c7.6-7.2,7.9-19.2,0.7-26.8L564.6,281.5z" />
        <path d="M42.8,357.9l46.4-48.8l46.4,48.8c3.7,3.9,8.8,5.9,13.8,5.9c4.7,0,9.4-1.7,13.1-5.2c7.6-7.2,7.9-19.2,0.7-26.8l-47.7-50.2l47.7-50.2c7.2-7.6,6.9-19.6-0.7-26.8c-7.6-7.2-19.6-6.9-26.8,0.7l-46.4,48.8l-46.4-48.8c-7.2-7.6-19.2-7.9-26.8-0.7c-7.6,7.2-7.9,19.2-0.7,26.8l47.7,50.2l-47.7,50.2c-7.2,7.6-6.9,19.6,0.7,26.8c3.7,3.5,8.4,5.2,13.1,5.2C34,363.8,39,361.8,42.8,357.9z" />
        <path d="M599.1,582.8c-264.5-168.4-471.9-52-560.5-2.4c-9.1,5.1-12.4,16.7-7.3,25.8c5.1,9.2,16.7,12.4,25.8,7.3c82.6-46.3,276.2-154.9,521.5,1.3c3.2,2,6.7,3,10.2,3c6.3,0,12.4-3.1,16-8.8C610.5,600.2,607.9,588.4,599.1,582.8z" />
      </g>
    </Svg>
  </SvgContainer>
)

Error.propTypes = {
  baseline: PropTypes.bool
}
