import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { colors } from '../theme/colors'
import { breakpoints } from '../theme/breakpoints'
import classnames from 'classnames'
import { zIndexes } from '../theme/zIndexes'
import {history} from '../../config/store'
import { fonts } from '../theme/fonts'
import { H2 } from '../Typography'
import { Check } from '../Icon'

const StepperStyled = styled.div`
  z-index: ${zIndexes.flyOutWrapper};
  background: ${colors.white};
  position: relative;
  width: 100%;
  margin: -8rem auto 3rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 1rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  padding: 2rem 0;

  @media screen and (max-width: ${breakpoints.minPhone}) {
    margin: -4rem auto 3rem;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 90%;
    margin: -5rem auto 3rem;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .step-inner-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 4rem;
  }

  .step h2 {
    display: inline-block;
    color: ${colors.semiBlack};
    margin-bottom: 1rem;

    @media screen and (max-width: ${breakpoints.minTablet}) {
      font-size: 3.3vw;
    }
  }

  .step-inner-wrapper:after {
    content: '';
    display: block;
    width: 100%;
    position: relative;
    height: 5px;
    background-color: ${colors.lightSilver};
  }
  .step-inner-wrapper:before {
    content: '';
    display: block;
    width: 101%;
    position: relative;
    height: 5px;
    background-color: ${colors.lightSilver};
  }
  .step:last-child .step-inner-wrapper:after {
    background-color: ${colors.white};
  }
  .step:first-child .step-inner-wrapper:before {
    background-color: ${colors.white};
  }

  .active {
    color: ${colors.white};
    font-weight: bold;
  }
  .active .step-inner-wrapper:before,
  .active .step-inner-wrapper:after {
    background-color: ${colors.primary};
  }
  .active + .step:not(.active) .step-inner-wrapper:before {
    background-color: ${colors.primary};
  }

  .active + .step:not(.active) .step-circle,
  .step:not(.active):first-child .step-circle {
    font-size: 1.8rem;
    height: 38px;
    width: 38px;
    border: 4px solid ${colors.primary};
    color: ${colors.primary};
    box-shadow: none;
  }

  .step-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 -2px;
    z-index: 1;
  }

  .active .step-circle {
    background-color: ${colors.primary};
    padding-top: 1px;
    color: transparent;
    text-shadow: 0 0 0 ${colors.white};
    border: 4px solid ${colors.primary};
    box-shadow: none;
    position: relative;
  }

  .clickable.active:hover {
    cursor: pointer;

    .step-circle {
      border: 4px solid ${colors.primaryLight};
    }

    h2 {
      color: ${colors.primary};
    }
  }

  .step-circle {
    height: 27px;
    width: 27px;
    border-radius: 20px;
    border: 4px solid ${colors.lightSilver};
    background-color: ${colors.white};
    color: ${colors.lightSilver};
    font-family: ${fonts.headline};
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-top: -0.3rem;
    }
  }
`

const CheckStyled = styled(Check)`
  width: 1.5rem;
  height: 1.5rem;
  fill: ${colors.white};
  width: 1.5rem;
  top: 0.4rem;
`

const Stepper = ({ step }) => (
  <StepperStyled>
    <div
      className={classnames({
        step: true,
        active: step > 1,
        clickable: step < 5
      })}
    >
      <H2
        onClick={() => (step > 1 && step < 5 ? history.push('/busse') : null)}
      >
        Busauswahl
      </H2>
      <div className="step-inner-wrapper">
        <div className="step-inner">
          <div
            className="step-circle"
            onClick={() =>
              step > 1 && step < 5 ? history.push('/busse') : null
            }
          >
            <span>{step > 1 ? <CheckStyled /> : '1'}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      className={classnames({
        step: true,
        active: step > 2,
        clickable: step < 5
      })}
    >
      <H2
        onClick={() => (step > 2 && step < 5 ? history.push('/extras') : null)}
      >
        Extras
      </H2>
      <div className="step-inner-wrapper">
        <div className="step-inner">
          <div
            className="step-circle"
            onClick={() =>
              step > 2 && step < 5 ? history.push('/extras') : null
            }
          >
            <span>{step > 2 ? <CheckStyled /> : '2'}</span>
          </div>
        </div>
      </div>
    </div>
    <div className={classnames({ step: true, active: step > 3, clickable: step < 5 })}>
      <H2 onClick={() => (step > 3 && step < 5 ? history.push('/personendaten') : null)}>Deine Daten</H2>
      <div className="step-inner-wrapper">
        <div className="step-inner">
          <div className="step-circle">
            <span>{step > 3 ? <CheckStyled /> : '3'}</span>
          </div>
        </div>
      </div>
    </div>
    <div className={classnames({ step: true, active: step > 4 })}>
      <H2>Zusammenfassung</H2>
      <div className="step-inner-wrapper">
        <div className="step-inner">
          <div className="step-circle">
            <span>{step > 4 ? <CheckStyled /> : '4'}</span>
          </div>
        </div>
      </div>
    </div>
  </StepperStyled>
)

Stepper.propTypes = {
  step: PropTypes.number
}

export default Stepper
