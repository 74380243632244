import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import Booking from './bookingReducer'
import Bus from './busReducer'
import App from './appReducer'

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    Booking,
    Bus,
    App
  })
}
