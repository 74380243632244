import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { logError } from './config/log';
import { history, store } from './config/store';
import rootSaga from './sagas';
import { unregister } from './serviceWorker';

// Fix IOs blur Problem: https://stackoverflow.com/questions/45487878/cant-blur-input-in-ios
function isTextInput(node) {
  return ['INPUT', 'TEXTAREA', 'SELECT'].indexOf(node.nodeName) !== -1;
}
document.addEventListener(
  'touchstart',
  function(e) {
    if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
      document.activeElement.blur();
    }
  },
  false
);

async function init() {
  store.runSaga(rootSaga);
  unregister();

  renderApp(App);

  if (module.hot) {
    // Enable Webpack hot module replacement for app
    module.hot.accept('./App', () => {
      const nextApp = require('./App').default;
      renderApp(nextApp);
    });
  }

  function renderApp(Component) {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
}

init();

window.addEventListener('error', event => {
  logError(event.error, { uncaught: true });
});
