import React from 'react';
import Mountain from '../../images/mountain.jpg';
import { LayoutContent, LayoutSection } from '../../ui/Layout';
import Stage from '../../ui/Stage/Stage';
import styled from 'styled-components';
import { colors } from '../../ui/theme/colors';
import { breakpoints } from '../../ui/theme/breakpoints';
import { history } from '../../config/store';
import { TextField } from '../../ui/TextField/TextField';
import { H3 } from '../../ui/Typography';
import Button from '../../ui/Button/Button';
import Stepper from '../../ui/Stepper/Stepper';
import * as ReactDOM from 'react-dom';
import { ChevronR } from '../../ui/Icon';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';

const InputWrapper = styled.div`
  border: 1px solid ${colors.lightGrey};
  padding: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3.5rem;
`;

const FieldWrapper = styled.div`
  display: flex;
  min-width: 26rem;
  flex-grow: 1;
  margin-bottom: 2rem;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%;
  }

  > span {
    width: 100%;

    &:first-child {
      margin-right: 2rem;
    }
  }

  input {
    width: 100%;
  }
`;

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SubmitWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function BookingForm() {
  const [errors, setErrors] = React.useState({});
  const booking = useSelector(store => store.Booking.store);

  React.useEffect(() => {
    if (!booking.bus || !booking.startDate || !booking.endDate) {
      history.push('/');
    }
  }, [booking.bus, booking.endDate, booking.startDate]);

  const vNameFieldRef = React.useRef();
  const dispatch = useDispatch();

  const validateFields = () => {
    let hasErrors = false;
    // simplified implementation of email validation
    const mailRegExp = /\S+@\S+\.\S\S+/;
    let errors = {};
    if (!booking.vname) {
      errors = { ...errors, vname: 'Vorname bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.nname) {
      errors = { ...errors, nname: 'Nachname bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.email) {
      errors = { ...errors, email: 'E-Mail Adresse bitte angeben!' };
      hasErrors = true;
    } else if (!mailRegExp.test(String(booking.email).toLowerCase())) {
      errors = { ...errors, email: 'Bitte valide E-Mail Adresse angeben!' };
      hasErrors = true;
    }
    if (!booking.tel) {
      errors = { ...errors, tel: 'Telefonnummer bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.strasse) {
      errors = { ...errors, strasse: 'Straße bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.plz) {
      errors = { ...errors, plz: 'PLZ bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.ort) {
      errors = { ...errors, ort: 'Ort bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.lander) {
      errors = { ...errors, lander: 'Reiseländer bitte angeben!' };
      hasErrors = true;
    }
    setErrors(errors);

    if (hasErrors) {
      scrollToRequiredFields();
    }

    return hasErrors;
  };

  const scrollToRequiredFields = () => {
    const topOfField = ReactDOM.findDOMNode(
      vNameFieldRef.current
    ).getBoundingClientRect().top;
    if (window.pageYOffset > topOfField) {
      window.scroll(0, topOfField - 20);
    }
  };

  const onChange = ({ fieldName, value }) => {
    dispatch(actions.setBookingValue(fieldName, value));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!validateFields()) {
      history.push('/zusammenfassung');
    }
  };

  return (
    <div>
      <Stage
        imgSrc={Mountain}
        alt={'Gruppenbus'}
        slogan={'Sage uns wer du bist'}
        small
      />
      <LayoutContent>
        <LayoutSection>
          <Stepper step={3} />
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <H3>Buchungsdaten</H3>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="firma"
                    label="Firmenname"
                    placeholder="Firmenname"
                    value={booking.firma}
                    onChange={value => onChange({ fieldName: 'firma', value })}
                  />
                </FieldWrapper>
              </FieldsWrapper>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    ref={vNameFieldRef}
                    name="vname"
                    label="Vorname*"
                    placeholder="Vorname"
                    value={booking.vname}
                    onChange={value => onChange({ fieldName: 'vname', value })}
                    error={errors.vname}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <TextField
                    name="nname"
                    label="Nachname*"
                    placeholder="Nachname"
                    value={booking.nname}
                    onChange={value => onChange({ fieldName: 'nname', value })}
                    error={errors.nname}
                  />
                </FieldWrapper>
              </FieldsWrapper>
              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="strasse"
                    label="Straße, Hausnummer*"
                    placeholder="Straße"
                    value={booking.strasse}
                    onChange={value =>
                      onChange({ fieldName: 'strasse', value })
                    }
                    error={errors.strasse}
                  />
                </FieldWrapper>
              </FieldsWrapper>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="plz"
                    label="PLZ*"
                    placeholder="PLZ"
                    value={booking.plz}
                    onChange={value => onChange({ fieldName: 'plz', value })}
                    error={errors.plz}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    name="ort"
                    label="Ort*"
                    placeholder="Ort"
                    value={booking.ort}
                    onChange={value => onChange({ fieldName: 'ort', value })}
                    error={errors.ort}
                  />
                </FieldWrapper>
              </FieldsWrapper>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="email"
                    label="E-Mail*"
                    placeholder="E-Mail"
                    type="email"
                    value={booking.email}
                    onChange={value => onChange({ fieldName: 'email', value })}
                    error={errors.email}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    name="tel"
                    label="Telefon*"
                    placeholder="Telefon"
                    value={booking.tel}
                    onChange={value => onChange({ fieldName: 'tel', value })}
                    type="tel"
                    error={errors.tel}
                  />
                </FieldWrapper>
              </FieldsWrapper>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="lander"
                    label="In welche Länder reist du?*"
                    placeholder="Reiseländer"
                    type="lander"
                    value={booking.lander}
                    onChange={value => onChange({ fieldName: 'lander', value })}
                    error={errors.lander}
                  />
                </FieldWrapper>
              </FieldsWrapper>

              <FieldsWrapper>
                <FieldWrapper>
                  <TextField
                    name="kommentar"
                    label="Kommentar"
                    placeholder="Kommentar"
                    type="textarea"
                    value={booking.kommentar}
                    onChange={value =>
                      onChange({ fieldName: 'kommentar', value })
                    }
                  />
                </FieldWrapper>
              </FieldsWrapper>
            </InputWrapper>

            <SubmitWrapper>
              <Button type="submit" big rounded Icon={ChevronR}>
                Zur Zusammenfassung
              </Button>
            </SubmitWrapper>
          </form>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
