import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${(props) => (props.baseline ? '-1.1rem' : '0')};
`;

export const CarParking = (props) => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 291.04 291.04" {...props}>
      <g>
        <path
          d="M47.287,18.958h-18.75v37.5h9.375v-9.375h9.375c7.753,0,14.063-6.309,14.063-14.063
				C61.35,25.265,55.041,18.958,47.287,18.958z M47.287,37.708h-9.375v-9.375h9.375c2.583,0,4.688,2.1,4.688,4.688
				C51.975,35.609,49.87,37.708,47.287,37.708z"
        />
        <path
          d="M216.038,243.958c0-25.847-21.028-46.875-46.875-46.875c-25.847,0-46.875,21.028-46.875,46.875
				c0,15.342,7.444,28.945,18.872,37.5H70.725v-18.75H56.662V159.583h-9.375V75.208h21.839l16.073-37.5l-16.073-37.5H16.073
				L0,37.708l16.073,37.5h21.839v84.375h-9.375v103.125H14.475v18.75H0.412v9.375h14.063h56.25h220.313v-9.375h-93.872
				C208.594,272.903,216.038,259.299,216.038,243.958z M22.252,65.833L10.2,37.708L22.252,9.583h40.697L75,37.708L62.948,65.833
				H22.252z M37.912,168.958h9.375v93.75h-9.375V168.958z M61.351,281.457H23.85v-9.375h4.688h28.125h4.688V281.457z
				 M169.163,281.457c-20.677,0-37.5-16.823-37.5-37.5s16.823-37.5,37.5-37.5s37.5,16.823,37.5,37.5S189.84,281.457,169.163,281.457
				z"
        />
        <path
          d="M259.008,37.708c-19.148,0-35.925,12.933-40.8,31.448l-15.159,57.614h-24.511c-31.584,0-60.816,16.861-76.795,44.095
				l-12.267,4.088v31.505h-18.75v37.5h18.75h9.375h14.063v-9.375H98.85v-28.125v-24.745l12.834-4.28
				c16.87-5.625,34.439-8.475,52.223-8.475h3.366c20.625,0,39.839,9.239,52.72,25.341c9.145,11.428,14.297,25.673,14.714,40.284
				h-9.295v9.375h9.375h4.688h51.563v-9.375H244.14c-0.417-16.734-6.352-33.056-16.823-46.144
				c-14.672-18.337-36.553-28.856-60.042-28.856h-3.366c-16.172,0-32.175,2.236-47.686,6.642
				c14.991-18.811,37.875-30.08,62.316-30.08h31.739l17.002-64.598c3.788-14.405,16.838-24.464,31.73-24.464h32.03v-9.375H259.008z
				 M89.475,234.583H80.1v-18.75h9.375V234.583z"
        />
        <path
          d="M169.163,220.52c-12.923,0-23.438,10.514-23.438,23.438c0,12.924,10.514,23.437,23.438,23.437
				c12.923,0,23.438-10.514,23.438-23.437C192.601,231.036,182.086,220.52,169.163,220.52z M169.163,258.02
				c-7.753,0-14.063-6.309-14.063-14.062c0-7.753,6.309-14.063,14.063-14.063c7.753,0,14.063,6.309,14.063,14.063
				C183.225,251.71,176.916,258.02,169.163,258.02z"
        />
        <path
          d="M237.778,71.585l-13.748,51.563c-1.134,4.252-0.248,8.695,2.438,12.192c2.686,3.497,6.745,5.494,11.147,5.494h53.423
				v-9.375h-53.423c-1.467,0-2.822-0.67-3.717-1.833c-0.891-1.167-1.191-2.644-0.811-4.064l13.748-51.563
				c0.548-2.048,2.409-3.478,4.533-3.478h39.67v-9.375h-39.67C245.006,61.145,239.414,65.439,237.778,71.585z"
        />
      </g>
    </Svg>
  </SvgContainer>
);

CarParking.propTypes = {
  baseline: PropTypes.bool,
};
