import React from 'react';
import BusResultCard from './BusResultCard';
import Mountain from '../../images/9-sitzer-bus-background.jpg';
import { LayoutContent, LayoutSection } from '../../ui/Layout';
import Stage from '../../ui/Stage/Stage';
import { Datepicker } from '../common/Datepicker/Datepicker';
import styled from 'styled-components';
import { colors } from '../../ui/theme/colors';
import Select from '../../ui/Select/Select';
import { breakpoints } from '../../ui/theme/breakpoints';
import KilometerSelector from '../../ui/KilometerSelector/KilometerSelector';
import { history } from '../../config/store';
import Stepper from '../../ui/Stepper/Stepper';
import { H2 } from '../../ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import Loader from '../../ui/Loader/Loader';

const InputWrapper = styled.div`
  border-bottom: 1px solid ${colors.lightSilver};
  padding: 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 4rem;
  box-shadow: none;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4rem solid transparent;
    border-right: 4rem solid transparent;
    border-top: 1.5rem solid ${colors.lightSilver};
    position: relative;
    top: 3.5rem;
    left: calc(50% - 4rem);
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  min-width: 26rem;
  > span:first-child {
    margin-right: 2rem;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%;
  }
`;

export function BusSelection() {
  const booking = useSelector((store) => store.Booking.store);
  const busses = useSelector((store) =>
    Object.values(store.Bus.store).sort((a, b) => a.price - b.price)
  );
  const loadingBusses = useSelector((store) => store.Bus.loading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.calculateAllPrices(booking));
  }, [booking, dispatch]);

  const [errors, setErrors] = React.useState({});

  const validateFields = () => {
    let hasErrors = false;
    let errors = {};
    if (!booking.km) {
      errors = { ...errors, km: 'Kilometer bitte angeben!' };
      hasErrors = true;
    }
    if (!booking.startDate || !booking.endDate) {
      errors = { ...errors, date: 'Das Reisedatum muss angegeben werden!' };
      hasErrors = true;
    }
    setErrors(errors);
    return hasErrors;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    if (startDate) {
      dispatch(actions.setBookingValue('startDate', startDate));
    }
    if (endDate) {
      dispatch(actions.setBookingValue('endDate', endDate));
    }
    if ((!startDate && !endDate) || (startDate && endDate)) {
      validateFields();
    }
  };

  const onChange = ({ fieldName, value }) => {
    dispatch(actions.setBookingValue(fieldName, value));
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      history.push('/extras');
    } else {
      alert('Sie haben nicht alle Pflichtfelder ausgefüllt');
    }
  };

  return (
    <div>
      <Stage
        imgSrc={Mountain}
        alt={'Gruppenbus'}
        slogan={'Wähle dein Fahrzeug'}
        small
      />
      <LayoutContent>
        <LayoutSection>
          <Stepper step={1} />
          <form>
            <InputWrapper>
              <Datepicker
                error={errors.date}
                label={'Reisezeitraum*'}
                onChange={handleDateChange}
                startDate={booking.startDate}
                endDate={booking.endDate}
              />
              <TimeWrapper>
                <Select
                  htmlId={'startTime'}
                  label={'Startzeit*'}
                  onChange={(value) =>
                    onChange({ fieldName: 'startTime', value })
                  }
                  initialValue={booking.startTime}
                  options={[
                    '09:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                  ]}
                  afterLabel={'Uhr'}
                />
                <Select
                  htmlId={'endTime'}
                  label={'Endzeit*'}
                  onChange={(value) =>
                    onChange({ fieldName: 'endTime', value })
                  }
                  initialValue={booking.endTime}
                  options={[
                    '00:00',
                    '01:00',
                    '02:00',
                    '03:00',
                    '04:00',
                    '05:00',
                    '06:00',
                    '07:00',
                    '08:00',
                    '09:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00',
                    '19:00',
                    '20:00',
                    '21:00',
                    '22:00',
                    '23:00',
                  ]}
                  afterLabel={'Uhr'}
                />
              </TimeWrapper>
              <KilometerSelector
                onChange={(value) => onChange({ fieldName: 'km', value })}
                km={booking.km}
                error={errors.km}
              />
            </InputWrapper>
            <H2 center>Wähle einen Kleinbus:</H2>
            {booking.startDate && booking.endDate && loadingBusses ? (
              <Loader />
            ) : (
              busses.map((bus) => (
                <BusResultCard
                  id={bus.id}
                  key={bus.id}
                  onClick={(bus) => {
                    onChange({ fieldName: 'bus', value: bus });
                    handleSubmit();
                  }}
                />
              ))
            )}
          </form>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
