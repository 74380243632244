import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

const middleware = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);
middleware.push(routerMiddleware(history));

if (
  (process.env.NODE_ENV === 'development' &&
    localStorage.getItem('reduxLogs')) ||
  window.Cypress
) {
  middleware.push(createLogger());
}

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middleware)),
);

if (window.Cypress) {
  window.store = store;
}

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('../reducers', () => {
    const nextRootReducer = require('../reducers').default;
    store.replaceReducer(nextRootReducer(history));
  });
}

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);

export { store, history };
