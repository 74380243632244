export const DateHelper = {
  getEasterDateForYear: function (year) {
    // https://www.irt.org/articles/js052/index.htm
    function paddingLeft(number) {
      return number < 10 ? '0' + number : number;
    }

    const C = Math.floor(year / 100);
    const N = year - 19 * Math.floor(year / 19);
    const K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I =
      I -
      Math.floor(I / 28) *
        (1 -
          Math.floor(I / 28) *
            Math.floor(29 / (I + 1)) *
            Math.floor((21 - N) / 11));
    let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    const L = I - J;
    const M = 3 + Math.floor((L + 40) / 44);
    const D = L + 28 - 31 * Math.floor(M / 4);

    return new Date(year + '-' + paddingLeft(M) + '-' + paddingLeft(D));
  },
  isHoliday: function (date) {
    // Feste Feiertage werden nach dem Schema dd-mm eingetragen
    const holidays = [];
    holidays.push('1-0'); // Neujahrstag
    holidays.push('3-9'); // Tag der Deutschen Einheit
    holidays.push('25-11'); // Erster Weihnachtstag
    holidays.push('26-11'); // Zweiter Weihnachtstag

    // Bewegliche Feiertage berechnen
    const tage = 60 * 60 * 24 * 1000;
    const ostersonntag = this.getEasterDateForYear(date.getFullYear());
    const karfreitag = new Date(ostersonntag.getTime() - 2 * tage);
    holidays.push(karfreitag.getDate() + '-' + karfreitag.getMonth());
    const ostermontag = new Date(ostersonntag.getTime() + tage);
    holidays.push(ostermontag.getDate() + '-' + ostermontag.getMonth());
    const himmelfahrt = new Date(ostersonntag.getTime() + 39 * tage);
    holidays.push(himmelfahrt.getDate() + '-' + himmelfahrt.getMonth());
    const pfingstmontag = new Date(ostersonntag.getTime() + 50 * tage);
    holidays.push(pfingstmontag.getDate() + '-' + pfingstmontag.getMonth());

    // Prüfen, ob Feiertag
    const m = date.getMonth(),
      d = date.getDate();
    for (let i = 0; i < holidays.length; i++) {
      if (holidays.indexOf(d + '-' + m) !== -1) {
        return true;
      }
    }
    return false;
  },
  isSundayOrHoliday: function (date) {
    return date.getDay() === 0 || this.isHoliday(date);
  },
  isSaturday: function (date) {
    return date.getDay() === 6;
  },
};
