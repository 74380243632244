import React from 'react'
import { PropTypes } from 'prop-types'
import { breakpoints } from '../theme/breakpoints'
import styled from 'styled-components'
import Media from 'react-media'
import { colors } from '../theme/colors'
import { fonts } from '../theme/fonts'

const IntroHomeDesktop = styled.div`
  background: url(${props => props.imgSrc}) no-repeat center center;
  background-size: cover;
  height: ${props => (props.small ? '38vh' : 'calc(100vh - 8rem)')};
  position: relative;
  color: ${colors.white};
  margin-bottom: 2rem;
  margin-top: 8rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0)
    ) !important;
  }
`

const IntroSlogan = styled.h1`
  position: absolute;
  top: 38%;
  transform: translateY(20%);
  font-size: 4vh;
  margin: 0;
  text-align: center;
  width: 100%;
  padding: 0 5%;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.25); 


  font-family: ${fonts.headline};
  font-weight: 400;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    font-size: 6vh;
  }

  @media screen and (max-width: ${breakpoints.minPhone}) {
      font-size: 9vw;
      top: 48%;
  }
`

const IntroHomeMobile = styled.div`
  position: relative;
  color: ${colors.white};
  margin-bottom: 2rem;
  padding-top: 8rem;

  > img {
    max-width: 100%;
  }
`

const Stage = ({ imgSrc, alt, subTitle, slogan, ...props }) => (
  <Media query={`(min-width: ${breakpoints.minTablet})`}>
    {isDesktop =>
      isDesktop ? (
        <IntroHomeDesktop imgSrc={imgSrc} {...props}>
          <IntroSlogan>{slogan}</IntroSlogan>
        </IntroHomeDesktop>
      ) : (
        <IntroHomeMobile>
          <div>
            <IntroSlogan>{slogan}</IntroSlogan>
          </div>
          <img src={imgSrc} alt={alt} />
        </IntroHomeMobile>
      )
    }
  </Media>
)

Stage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  slogan: PropTypes.string.isRequired,
  small: PropTypes.bool
}

export default Stage
