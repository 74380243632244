import React from 'react';
import { breakpoints } from '../../ui/theme/breakpoints';
import { colors } from '../../ui/theme/colors';
import styled from 'styled-components';
import CockpitBackground from './bg_white.svg';
import { Datepicker } from '../common/Datepicker/Datepicker';
import Stage from '../../ui/Stage/Stage';
import { H3 } from '../../ui';
import Button from '../../ui/Button/Button';
import IntroBackground from '../../images/roadSmall.jpg';
import BusImage from '../../images/9-sitzer-bus-background.jpg';
import { zIndexes } from '../../ui/theme/zIndexes';
import { ChevronR } from '../../ui/Icon';
import { history } from '../../config/store';
import BusIntro from '../../ui/BusIntro/BusIntro';
import TextImage from '../../ui/TextImage/TextImage';
import * as ReactDOM from 'react-dom';
import GoogleMap from '../../ui/GoogleMap/GoogleMap';
import { H2 } from '../../ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';

// webpack responsive-loader config
// const IntroBackground = require('responsive-loader?placeholder=true&sizes[]=500,sizes[]=1000!../../images/roadSmall2.jpg')

const BusSearchCockpit = styled.form`
  z-index: ${zIndexes.flyOutWrapper};
  position: relative;
  background-color: ${colors.white};
  width: 95%;
  border-radius: 5px;
  box-shadow: 0 5px 5px ${colors.lightGrey};
  transition: max-width 0.5s;
  min-width: 26rem;
  margin: -7rem auto 6rem;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  top: 0;
  left: auto;
  right: auto;

  @media screen and (min-width: ${breakpoints.minTablet}) {
    position: absolute;
    width: 100%;
    top: 55%;
    margin: 0 auto;
    height: 170px;
    background: url('${CockpitBackground}') no-repeat center;
    background-size: contain;
    box-shadow: none;
  }

  > span {
    margin-bottom: 2rem;
  }
`;

const BusSearchCockpitSticky = styled(BusSearchCockpit)`
  opacity: ${(props) => (props.showSticky ? '1' : '0')};
  transform: ${(props) =>
    props.showSticky ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.5s, opacity 0.5s;
  position: fixed;
  width: 100%;
  border-radius: 0;
  border-bottom: 5px solid ${colors.orange};
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  margin: 0;
  flex-wrap: nowrap;
  left: 0;
  right: 0;
  height: ${(props) => (props.isDatepickerOpen ? '100%' : 'auto')};
  align-items: ${(props) => (props.isDatepickerOpen ? 'flex-start' : 'center')};

  @media screen and (min-width: ${breakpoints.minTablet}) {
    position: fixed;
    top: 0;
    height: auto;
    background: ${colors.white};
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  }

  > span {
    margin-bottom: 0;
  }
`;

const BusSearchCockpitSubmit = styled(Button)`
  margin-bottom: 2rem;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    margin-left: 1rem;
    border-radius: 2rem;
  }
  svg {
    @media screen and (max-width: ${breakpoints.minTablet}) {
      margin-left: 0;
    }
  }
`;

const BusSearchCockpitSubmitSticky = styled(BusSearchCockpitSubmit)`
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.minTablet}) {
    padding: 0.5rem;

    svg {
      margin-top: 0rem;
    }
  }
`;

const H3Styled = styled(H3)`
  margin-bottom: 2rem;
  margin-right: 2rem;
  color: ${colors.semiBlack};

  @media screen and (max-width: ${breakpoints.minTablet}) {
    display: none;
  }
`;

const H3StyledSticky = styled(H3Styled)`
  margin-bottom: 0;
`;

const ButtonTextSticky = styled.span`
  @media screen and (max-width: ${breakpoints.minTablet}) {
    display: none;
  }
`;

export function Startpage() {
  const [errors, setErrors] = React.useState({});
  const [showSticky, setShowSticky] = React.useState(false);
  const [offsetTop, setOffsetTop] = React.useState(500);
  const [datepickerOpen, setDatepickerOpen] = React.useState(false);
  const busSearchCockpitRef = React.useRef();
  const booking = useSelector((store) => store.Booking.store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (window.location.search.includes('anzeige=isar')) {
      dispatch(actions.createAnzeigeClick('isar'));
      history.push('/');
    }
  }, [dispatch]);

  const onScroll = React.useCallback(() => {
    if (!datepickerOpen) {
      if (getScrollPosition() > offsetTop) {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    }
  }, [datepickerOpen, offsetTop]);

  const getScrollPosition = () => {
    return (
      window.scrollY ||
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      0
    );
  };
  const setOffset = React.useCallback(() => {
    if (busSearchCockpitRef.current) {
      const offsetTop = ReactDOM.findDOMNode(
        busSearchCockpitRef.current
      ).getBoundingClientRect().top;
      setOffsetTop(offsetTop + getScrollPosition() + 80);
    }
  }, []);

  React.useEffect(() => {
    window.document.body.onload = setOffset;
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', setOffset);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', setOffset);
    };
  }, [onScroll, setOffset]);

  const validateFields = () => {
    let hasErrors = false;
    let errors = {};
    if (!booking.startDate || !booking.endDate) {
      errors = { ...errors, date: 'Das Reisedatum muss angegeben werden!' };
      hasErrors = true;
    }
    setErrors(errors);
    return hasErrors;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    if (startDate) {
      dispatch(actions.setBookingValue('startDate', startDate));
    }
    if (endDate) {
      dispatch(actions.setBookingValue('endDate', endDate));
    }
    if ((!startDate && !endDate) || (startDate && endDate)) {
      validateFields();
    }
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!validateFields()) {
      history.push('/busse');
    }
  };

  return (
    <div>
      <Stage
        imgSrc={IntroBackground}
        alt={'Gruppe von Menschen fährt mit einem Bus'}
        slogan={'Freunde am Fahren ...'}
      />
      <BusSearchCockpit onSubmit={handleSubmit} ref={busSearchCockpitRef}>
        <H3Styled>Zeitraum: </H3Styled>
        <Datepicker
          error={errors.date}
          onChange={handleDateChange}
          startDate={booking.startDate}
          endDate={booking.endDate}
          onClose={handleSubmit}
        />
        <BusSearchCockpitSubmit type={'submit'} Icon={ChevronR} big>
          Zu den Bussen
        </BusSearchCockpitSubmit>
      </BusSearchCockpit>
      <BusSearchCockpitSticky
        showSticky={showSticky}
        onSubmit={handleSubmit}
        isDatepickerOpen={datepickerOpen}
      >
        <H3StyledSticky>Zeitraum: </H3StyledSticky>
        <Datepicker
          error={errors.date}
          onChange={handleDateChange}
          startDate={booking.startDate}
          endDate={booking.endDate}
          onClose={() => {
            setDatepickerOpen(false);
            handleSubmit();
          }}
          onOpen={() => {
            setDatepickerOpen(true);
          }}
        />
        <BusSearchCockpitSubmitSticky type={'submit'} Icon={ChevronR} big>
          <ButtonTextSticky>Zu den Bussen</ButtonTextSticky>
        </BusSearchCockpitSubmitSticky>
      </BusSearchCockpitSticky>
      <BusIntro />
      <TextImage
        image={BusImage}
        bgPosX={'right'}
        bgPosY={'top'}
        title={'Über uns'}
        subtitle={'9-Sitzer / Kleinbusvermietung Gruppenbus'}
        text={
          <span>
            Wir sind ein engagiertes Team, das es sich zur Aufgabe gemacht hat,
            Kleinbusse und 9-Sitzer mit viel Platz zu vermieten. Unsere Busse
            sind größer als die, die man bei gängigen Busvermietungen bekommt.
            Die Busse haben ein Hochdach, sodass man im Bus stehen kann und die
            9-Sitzer sind sehr lang.
            <br /> <br /> Die Busse bieten Platz für 2 bis 9 Personen. Mit einem
            normalen Führerschein der Klasse B könnt ihr diese Busse problemlos
            fahren. <br />
            Die 9-Sitzer besitzen eine Trennwand zwischen der Fahrgastkabine und
            dem Kofferraum. Damit lässt sich euer Gepäck bis an die Decke
            stapeln und ihr braucht keine Sorge zu haben, dass bei einem
            Bremsmanöver alles nach vorn fällt. Bei den größten Bussen ist
            zusätzlich ein Monitor mit DVD Anlage vorhanden, sodass ihr während
            der Fahrt DVDs schauen könnt. <br />
            <br />
            Wir haben verschiedene Modelle, vom VW Crafter, Mercedes Sprinter
            oder MAN TGE. Die kleinen Busse sind VW T5 Busse. Unsere Kleinbusse
            haben sehr spritsparende Motoren mit einem Verbrauch von nur 8 - 10
            Liter/100km. <br />
            <br />
            Unsere Kleinbusse sind in ganz Europa versichert. Somit sind auch
            Auslandsfahrten kein Problem.
          </span>
        }
        linkLabel={'Zu den Bussen'}
        target={'/busse'}
      />
      <H2 center>Unsere Adresse</H2>
      <GoogleMap />
    </div>
  );
}
