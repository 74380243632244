import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'


const ImageStyled = styled.div`
  width: 100%;
  background: url(${props => props.imgSrc}) center center;
  background-size: cover;
  height: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'}
`

const BackgroundImage = ({ ...props }) => (
  <ImageStyled {...props}/>
)

BackgroundImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  maxWidth: PropTypes.string
}

export default BackgroundImage
