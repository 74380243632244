import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Rampe = props => (
  <SvgContainer>
    <Svg viewBox="0 0 512 512" {...props}>
      <path d="m452 36h-203c-33.084 0-60 26.916-60 60v227.701l-183.54 93.479c-3.37 1.72-5.46 5.13-5.46 8.91v39.91c0 3.45 1.83 6.72 4.77 8.53 1.58.96 3.38 1.47 5.23 1.47 1.57 0 3.14-.38 4.54-1.09l65.96-33.6c4.91-2.5 6.88-8.53 4.37-13.45-1.71-3.37-5.13-5.46-8.92-5.46-1.58 0-3.1.37-4.53 1.09l-51.42 26.19v-17.46l169-86.238v17.468l-37.38 19.19c-4.91 2.51-6.87 8.54-4.37 13.45 1.72 3.37 5.13 5.47 8.92 5.47 1.58 0 3.11-.37 4.53-1.09l28.3-14.421v23.594 30.69c0 19.667 16 35.667 35.667 35.667s35.667-16 35.667-35.667v-20.69h180.334v20.69c0 19.667 16 35.667 35.666 35.667s35.666-16 35.666-35.667v-30.69-313.643c0-33.084-26.916-60-60-60zm-243 363.643v-19.909h283v19.909zm283-298.746v182.013h-283v-182.013zm-154-32.448c0-6.865 5.607-12.449 12.5-12.449s12.5 5.584 12.5 12.449-5.607 12.449-12.5 12.449-12.5-5.585-12.5-12.449zm-129 270.674c3.704-1.468 6.33-5.078 6.33-9.293 0-4.223-2.626-7.829-6.33-9.295v-17.625h71.5v56.825h-71.5zm283-18.588c-3.704 1.465-6.33 5.072-6.33 9.295 0 4.215 2.626 7.82 6.33 9.285v20.62h-71.5v-56.825h71.5zm-91.5 39.199h-20v-56.824h20zm-40 0h-20v-56.824h20zm-40 0h-20v-56.824h20zm168.53-278.837h-108.521c1.602-3.836 2.491-8.04 2.491-12.449s-.889-8.613-2.491-12.449h71.491c16.714.001 31.058 10.309 37.03 24.898zm-240.03-24.897h71.491c-1.602 3.836-2.491 8.04-2.491 12.449s.889 8.613 2.491 12.449h-108.521c5.972-14.59 20.316-24.898 37.03-24.898zm-8.667 384.333c0 8.639-7.028 15.667-15.667 15.667s-15.666-7.028-15.666-15.667v-20.69h31.333zm236 15.667c-8.639 0-15.666-7.028-15.666-15.667v-20.69h31.333v20.69c0 8.639-7.028 15.667-15.667 15.667z" />
      <path d="m250.33 319.83c-5.51 0-10 4.48-10 10 0 5.51 4.49 10 10 10 5.52 0 10-4.49 10-10 0-5.52-4.48-10-10-10z" />
      <path d="m450.67 339.82c5.51 0 10-4.48 10-9.99 0-5.52-4.49-10-10-10-5.521 0-10 4.48-10 10 0 5.51 4.479 9.99 10 9.99z" />
      <path d="m462 203.95c2.31 0 4.56-.8 6.34-2.26 2.07-1.7 3.35-4.09 3.61-6.75s-.521-5.26-2.221-7.33c-1.899-2.32-4.72-3.66-7.739-3.66-2.301 0-4.551.81-6.33 2.27-4.261 3.5-4.891 9.81-1.391 14.08 1.901 2.32 4.721 3.65 7.731 3.65z" />
      <path d="m390.01 263c2.3 0 4.55-.8 6.33-2.27l37.2-30.51c2.069-1.69 3.35-4.09 3.609-6.75.271-2.66-.52-5.26-2.21-7.32-1.909-2.33-4.729-3.66-7.739-3.66-2.301 0-4.551.81-6.341 2.27l-37.199 30.51c-2.07 1.69-3.351 4.09-3.61 6.75-.27 2.66.52 5.26 2.22 7.32 1.9 2.33 4.72 3.66 7.74 3.66z" />
      <path d="m450.005 263.001c2.282 0 4.577-.777 6.456-2.367l12-10.158c4.215-3.568 4.74-9.878 1.172-14.094-3.569-4.216-9.877-4.739-14.094-1.172l-12 10.158c-4.215 3.568-4.74 9.878-1.172 14.094 1.979 2.337 4.799 3.539 7.638 3.539z" />
      <path d="m116.05 401.98c-1.58 0-3.11.36-4.53 1.09-2.38 1.21-4.14 3.28-4.97 5.82s-.61 5.25.6 7.63c1.72 3.37 5.13 5.46 8.92 5.46 1.58 0 3.11-.37 4.53-1.09 2.38-1.21 4.15-3.28 4.97-5.82.83-2.54.61-5.25-.6-7.63-1.72-3.37-5.13-5.46-8.92-5.46z" />
    </Svg>
  </SvgContainer>
)

Rampe.propTypes = {
  baseline: PropTypes.bool
}
