import React from 'react';
import Mountain from '../../images/mountain.jpg';
import { LayoutContent, LayoutSection } from '../../ui/Layout';
import Stage from '../../ui/Stage/Stage';
import styled from 'styled-components';
import { H2 } from '../../ui/Typography';
import Stepper from '../../ui/Stepper/Stepper';
import { history } from '../../config/store';
import { ChevronR } from '../../ui/Icon';
import Button from '../../ui/Button/Button';
import BookingBox from '../common/BookingBox';
import { useSelector } from 'react-redux';
import Checkbox from '../../ui/Checkbox/Checkbox';
import { colors } from '../../ui/theme/colors';

const SubmitWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`;

const DatenSchutzWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 55rem;
  margin-top: 2rem;
`;

const FehlerText = styled.span`
  color: ${colors.red};
`;

export function Summary() {
  const booking = useSelector(store => store.Booking.store);
  const busses = useSelector(store => store.Bus.store);
  const [datenschutz, setDatenSchutz] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (
      !booking.bus ||
      !booking.startDate ||
      !booking.endDate ||
      !busses[booking.bus].priceBus
    ) {
      history.push('/');
    }
  }, [booking, busses]);

  const handleClick = () => {
    if (!datenschutz) {
      setError('Bitte akzeptiere den Datenschutzhinweis.');
      return;
    }
    //Submit Booking
    history.push('/fertig');
  };

  return (
    <div>
      <Stage
        imgSrc={Mountain}
        alt={'Gruppenbus'}
        slogan={'Jetzt buchen!'}
        small
      />
      <LayoutContent>
        <LayoutSection centered>
          <Stepper step={4} />
          <br />
          <H2>Zusammenfassung</H2>
          <BookingBox extended />
          <DatenSchutzWrapper>
            <Checkbox
              name={'datenschutz'}
              id={'datenschutz'}
              value={1}
              onChange={() => setDatenSchutz(!datenschutz)}
              checked={datenschutz ? 'checked' : ''}
            />
            <label htmlFor="datenschutz">
              Ich bin damit einverstanden, dass meine Daten zum Zwecke der
              Auftragsabwicklung elektronisch gespeichert werden. Die Daten
              werden nicht an Dritte weitergegeben. *<br />
              {error && <FehlerText>{error}</FehlerText>}
            </label>
          </DatenSchutzWrapper>
          <SubmitWrapper>
            <Button onClick={handleClick} big rounded Icon={ChevronR}>
              Fahrzeug reservieren
            </Button>
          </SubmitWrapper>
        </LayoutSection>
      </LayoutContent>
    </div>
  );
}
