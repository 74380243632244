import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Snow = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 1000 1000" {...props}>
      <g><path d="M990,534.1v-68.3h-73.1c0,0-7.8-43.3,24.3-50.2c11.1-2.4-11.2-37.1-11.2-37.1l-35.5-20.5l-85.8,107.9h-111c-4.6-30.3-16-58.3-32.5-82.6l77.4-77.4l136.9,15.7l10.6-39.6c0,0-8.8-40.3-18.3-34.2c-27.6,17.8-52.7-18.3-52.7-18.3l51.7-51.7l-48.3-48.3l-51.7,51.7c0,0-36.2-25.1-18.4-52.7c6.1-9.5-34.2-18.3-34.2-18.3l-39.6,10.6l15.6,136.9l-76.3,76.4c-24.3-17.9-52.7-30.5-83.6-36.1V191.3l107.9-85.8L621.6,70c0,0-34.7-22.3-37.1-11.2c-6.9,32.1-50.3,24.3-50.3,24.3V10h-68.4v73.1c0,0-43.3,7.8-50.2-24.3C413.2,47.7,378.5,70,378.5,70L358,105.5l107.9,85.8v105.9c-31.4,5.1-60.2,17.5-85,35.2l-75-75l15.6-136.9l-39.6-10.6c0,0-40.3,8.8-34.2,18.3c17.8,27.6-18.3,52.7-18.3,52.7l-51.7-51.7l-48.3,48.3l51.7,51.7c0,0-25.1,36.1-52.7,18.3c-9.5-6.1-18.3,34.2-18.3,34.2l10.6,39.6l136.9-15.7l75.4,75.4c-17.2,24.7-29.1,53.5-33.8,84.6H191.3l-85.8-107.9L70,378.4c0,0-22.3,34.7-11.2,37.1c32.1,6.9,24.3,50.2,24.3,50.2H10v68.3h73.1c0,0,7.8,43.3-24.3,50.3C47.7,586.8,70,621.5,70,621.5l35.5,20.5l85.8-107.9h109c5.8,30.1,18.3,57.8,35.8,81.5L257.6,694l-136.9-15.6L110.1,718c0,0,8.8,40.3,18.3,34.2c27.6-17.8,52.7,18.3,52.7,18.3l-51.7,51.7l48.3,48.3l51.7-51.7c0,0,36.1,25.1,18.3,52.7c-6.1,9.5,34.2,18.3,34.2,18.3l39.6-10.6L306,742.4l79.5-79.5c23.7,16.1,51,27.3,80.4,32.1v113.7L358,894.5l20.5,35.5c0,0,34.7,22.3,37.1,11.2c6.9-32.1,50.2-24.3,50.2-24.3V990h68.4v-73.1c0,0,43.3-7.7,50.3,24.3c2.4,11.1,37.1-11.2,37.1-11.2l20.5-35.5l-107.9-85.8V694.5c29-5.2,55.8-16.7,79.1-32.9l80.8,80.8l-15.6,136.9l39.6,10.6c0,0,40.3-8.8,34.2-18.3c-17.8-27.6,18.3-52.7,18.3-52.7l51.7,51.7l19.1-19.1l13.2-13.2l16-16L819,770.5c0,0,25.1-36.1,52.7-18.3c9.5,6.1,18.3-34.2,18.3-34.2l-10.6-39.6L742.5,694l-80.4-80.3c16.8-23.3,28.8-50.3,34.4-79.6h112.2L894.5,642l35.5-20.5c0,0,22.3-34.8,11.2-37.1c-32.1-7-24.3-50.3-24.3-50.3L990,534.1L990,534.1z M500.6,629.9C427.9,629.9,369,571,369,498.3c0-72.7,58.9-131.6,131.6-131.6c72.6,0,131.6,58.9,131.6,131.6C632.2,571,573.3,629.9,500.6,629.9z"/></g>
    </Svg>
  </SvgContainer>
)

Snow.propTypes = {
  baseline: PropTypes.bool
}
