import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Router } from 'react-router-dom';
import { Startpage } from './components/Startpage/Startpage';
import { BusSelection } from './components/BusSelection/BusSelection';
import { Successpage } from './components/Successpage/Successpage';
import { BookingForm } from './components/BookingForm/BookingForm';
import Logo from './ui/Logo/Logo';
import { Layout, LayoutHeader } from './ui';
import { history } from './config/store';
import styled from 'styled-components';
import HeaderGrungeBackground from './images/grunge_top.png';
import { Footer } from './ui/Footer/Footer';
import Image from './ui/Image/Image';
import { colors } from './ui/theme/colors';
import { Phone } from './ui/Icon';
import { breakpoints } from './ui/theme/breakpoints';
import { zIndexes } from './ui/theme/zIndexes';
import ButtonPlain from './ui/Button/ButtonPlain';
import { ExtrasPage } from './components/ExtrasPage/ExtrasPage';
import { Summary } from './components/Summary/Summary';
import { Impressum } from './components/Impressum/Impressum';
import { AGB } from './components/AGB/AGB';
import { Kontakt } from './components/Kontakt/Kontakt';
import CookieOverlay from './ui/CookieOverlay/CookieOverlay';
// import HinweisOverlay from './ui/HinweisOverlay/HinweisOverlay';
import { useSelector } from 'react-redux';

const HeaderContact = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${colors.black};
    font-size: 1.4rem;
    padding: 1rem 2rem;
    border-radius: 2rem;

    @media screen and (min-width: ${breakpoints.minTablet}) {
      font-size: 2rem;
    }

    &:hover {
      color: ${colors.white};
      fill: ${colors.white};
      background: ${colors.primary};
    }

    &:hover svg {
      fill: ${colors.white};
    }

    svg {
      margin-right: 6px;
    }
  }
`;

const HeaderGrunge = styled.div`
  position: absolute;
  top: 7.8rem;
  z-index: ${zIndexes.systemMessageContainer};
  width: 100%;
  transform: translateY(-24%);

  div {
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-top: ${(132 / 1700) * 100}%;
      width: 100%;
    }

    > * {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

const FooterMenu = styled.div`
  list-style: none;

  li {
    display: inline-block;
    margin-right: 2rem;

    a {
      color: ${colors.white};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterButton = styled(ButtonPlain)`
  margin-bottom: 1rem;
  text-align: left;
`;

const ContentWrapper = styled.div`
  background-color: ${colors.white};
  padding-bottom: 18rem;
`;

const Copyright = styled.div`
  color: ${colors.darkGrey};
`;

function App() {
  const noScroll = useSelector((store) => store.App.noScroll);
  const footerMenu = [
    { text: '9-Sitzer mieten', action: () => history.push('/busse') },
    {
      text: 'Impressum & Datenschutz',
      action: () => history.push('/impressum'),
    },
    { text: 'Kontakt & Anfahrt', action: () => history.push('/kontakt') },
    { text: 'AGB', action: () => history.push('/agb') },
  ];

  return (
    <Layout noScroll={noScroll}>
      <Helmet>
        <title>Gruppenbus - Kleinbusvermietung & 9-Sitzer</title>
        <meta
          name="description"
          content="Kleinbus mieten Berlin ✓ 9-Sitzer ✓ MAN ✓ Crafter ✓ günstig &amp; freundlich ✓ Für Urlaub oder Tournee ✓ Mit Führerschein B fahrbar "
        />
        <meta
          property="og:image"
          content="https://gruppenbus.com/static/media/mittelanger_bus_front_links2.f4639765.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://gruppenbus.com/static/media/mittelanger_bus_front_links2.f4639765.jpg"
        />
        <meta property="og:image:width" content="476" />
        <meta property="og:image:height" content="281" />
        <meta
          property="og:image:alt"
          content="Kleinbus mieten Berlin - Gruppenbus"
        />
        <meta property="og:image:type" content="image/jpeg" />
      </Helmet>
      <div>
        <LayoutHeader large={false}>
          <Logo />
          <HeaderContact>
            <a rel="noopener noreferrer" href="tel:+493099271837">
              <Phone />
              <span>030 / 992 71 837</span>
            </a>
          </HeaderContact>
        </LayoutHeader>
        <HeaderGrunge>
          <div>
            <Image imgSrc={HeaderGrungeBackground} alt={'background'} />
          </div>
        </HeaderGrunge>
      </div>

      <Router history={history}>
        <ContentWrapper>
          <Route
            path="/"
            render={({ location }) => {
              if (window.location.hostname !== 'localhost' && window.gtag) {
                //Google Analytics 4 + Adwords
                window.gtag(
                  'set',
                  'page_path',
                  location.pathname + location.search
                );
                window.gtag('event', 'page_view');
              }
            }}
          />
          <Route exact path="/" component={Startpage} />
          <Route path="/busse" component={BusSelection} />
          <Route path="/extras" component={ExtrasPage} />
          <Route path="/personendaten" component={BookingForm} />
          <Route path="/zusammenfassung" component={Summary} />
          <Route path="/fertig" component={Successpage} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/agb" component={AGB} />
          <Route path="/kontakt" component={Kontakt} />
        </ContentWrapper>
      </Router>

      <Footer>
        <nav>
          <FooterMenu>
            {footerMenu.map((item, index) => (
              <li key={index}>
                <FooterButton onClick={item.action}>{item.text}</FooterButton>
              </li>
            ))}
          </FooterMenu>
        </nav>
        <Copyright>
          &copy; {new Date().getFullYear()} Gruppenbus - 9-Sitzer &
          Kleinbusvermietung
        </Copyright>
      </Footer>
      <CookieOverlay />
      {/*<HinweisOverlay />*/}
    </Layout>
  );
}

export default App;
