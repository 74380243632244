import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Radio = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 1000 1000" {...props}>
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M8706.3,4825.7l-59.6-51.4l-10.3-1127.9l-10.3-1125.9l-4037.1-10.3L552,2500l-96.6-47.3c-123.3-61.6-244.5-182.9-306.1-308.2L100,2048v-3122.9v-3122.9l55.5-104.8c65.7-123.3,191.1-242.4,322.5-304.1l94.5-43.1H5000h4427.5l117.1,57.5c127.4,61.6,236.3,170.5,308.2,306.1l47.2,88.3v3122.9V2048l-49.3,96.6c-61.6,125.3-182.8,246.5-306.1,308.2c-78.1,37-137.7,49.3-306.1,53.4l-211.6,8.2V3630v1117.7l-47.2,53.4C8911.8,4881.2,8786.5,4893.5,8706.3,4825.7z M9376.1,2082.9c41.1-20.5,86.3-65.8,106.8-106.8c34.9-67.8,37-254.8,37-3022.2c0-1623.1-6.2-2981.1-12.3-3016c-8.2-37-41.1-92.5-74-127.4l-61.6-59.6H5002.1h-4372l-55.5,51.4c-28.8,26.7-63.7,74-74,102.7c-12.3,34.9-20.5,1115.6-20.5,3026.3c0,2790,2.1,2977,37,3044.8c20.5,41.1,65.7,86.3,106.8,106.8c67.8,34.9,318.4,37,4376.1,37C9057.7,2119.9,9308.3,2117.8,9376.1,2082.9z" />
          <path d="M829.4,835.8v-791H5000h4170.6v791v791H5000H829.4V835.8z M8780.3,846.1V455.8h-616.3h-616.4v228.1c0,125.3-10.3,244.5-22.6,269.1c-34.9,65.7-152.1,104.8-236.3,78.1c-106.8-34.9-131.5-104.8-131.5-359.5V455.8H4188.5H1219.7v390.4v390.4H5000h3780.3V846.1z" />
          <path d="M1260.8-316.8c-193.1-69.8-359.5-242.4-410.9-429.4c-61.6-219.8,16.4-505.4,178.7-649.2c141.8-123.3,223.9-152,437.6-152c228.1,0,316.4,34.9,464.3,187c291.7,302,197.2,813.6-184.9,1008.8C1612.1-283.9,1396.4-267.4,1260.8-316.8z M1606-729.7c76-47.2,115.1-162.3,88.3-252.7c-34.9-117.1-110.9-174.6-230.1-174.6c-84.2,0-108.9,10.3-164.4,63.7C1090.3-883.8,1355.3-569.5,1606-729.7z" />
          <path d="M2892.1-312.6c-184.9-59.6-345.2-215.7-410.9-396.5c-14.4-43.1-26.7-143.8-28.8-223.9c0-256.8,137.7-464.3,386.2-579.4c96.6-43.2,131.5-49.3,279.4-41.1c193.1,10.3,300,57.5,421.2,182.8c384.2,394.5,127.4,1054-419.1,1078.6C3035.9-288,2941.4-296.2,2892.1-312.6z M3233.1-733.8c76-53.4,108.9-166.4,76-263c-26.7-86.3-135.6-170.5-213.7-170.5c-88.3,0-184.9,59.6-221.9,137.7C2762.6-799.6,3023.6-585.9,3233.1-733.8z" />
          <path d="M7068.9-304.4c-388.3-65.8-737.6-250.7-1012.9-536.2c-172.6-180.8-252.7-295.8-347.2-497.2c-123.3-256.8-160.3-437.6-160.3-764.3c0-328.7,37-507.5,160.3-768.4c544.4-1146.4,2052.5-1407.3,2937.9-507.5c901.9,914.2,595.8,2449-589.6,2954.4C7773.6-304.4,7368.8-255.1,7068.9-304.4z M7701.7-727.7c67.8-16.4,201.3-67.8,297.9-115c135.6-65.7,209.6-121.2,351.3-260.9c152-150,191.1-205.5,271.2-371.9c117.1-248.6,147.9-371.9,147.9-626.6c0-254.8-30.8-378-147.9-626.6c-80.1-166.4-119.2-219.8-271.2-369.8c-106.8-106.8-226-201.3-304.1-242.4c-791-417.1-1707.3-74-2025.7,760.2c-61.6,164.4-65.7,182.8-65.7,478.7c0,295.8,4.1,314.3,65.7,478.7C6288.2-926.9,6984.6-555.1,7701.7-727.7z" />
          <path d="M7118.2-1200.2C6658-1323.5,6358-1783.7,6434-2248c61.6-367.7,332.8-663.6,692.4-758.1c587.6-152,1160.8,293.8,1160.8,904c0,269.1-108.9,515.7-306.1,694.4C7744.8-1198.1,7420.2-1118,7118.2-1200.2z M7578.4-1617.3c102.7-45.2,203.4-143.8,260.9-256.8c32.9-61.6,45.2-121.2,45.2-228s-12.3-166.4-45.2-228.1c-180.8-349.3-628.7-410.9-883.4-121.2c-92.5,104.8-127.4,207.5-127.4,361.6c0,174.6,82.2,318.5,252.7,450C7190.1-1557.7,7416.1-1547.4,7578.4-1617.3z" />
          <path d="M4295.3-600.3c-234.2-86.3-304.1-357.5-143.8-548.6c102.7-121.2,271.2-154.1,417.1-80.1c110.9,55.5,170.5,147.9,180.8,279.4C4769.9-700.9,4527.5-514,4295.3-600.3z" />
          <path d="M880.7-2697.9l-51.4-49.3v-525.9v-528l59.6-59.6l59.6-59.6h813.6h811.5l57.5,49.3l57.5,49.3l6.2,526l6.2,528l-61.6,59.6l-59.6,61.6h-825.9H930L880.7-2697.9z M2288.1-3283.4V-3530h-534.2h-534.2v246.5v246.5h534.2h534.2V-3283.4z" />
        </g>
      </g>
    </Svg>
  </SvgContainer>
)

Radio.propTypes = {
  baseline: PropTypes.bool
}
