import React from 'react';
import styled from 'styled-components';
import { H1, Paragraph } from '../Typography';
import { LayoutContent, LayoutSection } from '../../ui';
import Image from '../Image/Image';
import BackgroundImage from '../../images/9-sitzer-bus-background.jpg';
import GrungeBottom from './shape_bottom.png';
import { breakpoints } from '../theme/breakpoints';

import BusOverviewCard from './BusOverviewCard';

import kleinbus1 from '../../images/minivan/kleinbus_front.jpg';
import kleinbus2 from '../../images/minivan/kleinbus_seite.jpg';
import kleinbus3 from '../../images/minivan/kleinbus_hinten.jpg';
import kleinbus5 from '../../images/minivan/kleinbus_cockpit.jpg';

import mittel1 from '../../images/man-mittel/man_mittel_front.jpg';
import mittel2 from '../../images/man-mittel/man_mittel_seite.jpg';
import mittel3 from '../../images/man-mittel/man_mittel_seite2.jpg';
import mittel4 from '../../images/man-mittel/man_mittel_hinten.jpg';
import mittel5 from '../../images/man-mittel/man_mittel_cockpit.jpg';

import lang1 from '../../images/kleinbus-lang/langer_bus_front.jpg';
import lang2 from '../../images/kleinbus-lang/langer_bus_seite.jpg';
import lang3 from '../../images/kleinbus-lang/langer_bus_hinten.jpg';
import lang4 from '../../images/kleinbus-lang/langer_bus_gadets.jpg';
import lang5 from '../../images/kleinbus-lang/langer_bus_cockpit.jpg';
import lang6 from '../../images/kleinbus-lang/langer_bus_screen.jpg';
import { history } from '../../config/store';
import Button from '../Button/Button';

const BusIntroWrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const SectionTitle = styled(H1)``;

const MoodImage = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.18);
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    display: none;
  }
`;

const MoodImageGrungeTop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateY(-71%);
`;

const MoodImageGrungeBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(50%);
`;

const BusCardWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: ${breakpoints.minTablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    > div {
      width: calc(50% - 3rem);
      margin: 1.5rem;
    }
  }

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    > div {
      width: calc(33% - 3rem);
      transform: translateY(-25%);

      &:nth-child(2) {
        transform: translateY(-45%);
      }
    }
  }
`;

const BusIntro = () => (
  <BusIntroWrapper>
    <LayoutContent>
      <LayoutSection centered={true}>
        <SectionTitle>Geräumige 9-Sitzer Busse mieten</SectionTitle>
        <Paragraph>
          Wir vermieten Kleinbusse mit bis zu 9 Sitzen für deinen Urlaub,
          Vereinsfahrt etc. Wir haben Busse in unterschiedlichen Größen und
          Ausstattungen. Alle Busse lassen sich mit einem Führerschein der
          Klasse B fahren. Man benötigt keinen Personenbeförderungsschein
          (P-Schein).
        </Paragraph>
      </LayoutSection>
    </LayoutContent>

    <MoodImage>
      <MoodImageGrungeTop>
        <Image imgSrc={GrungeBottom} alt={'grunge background image'} />
      </MoodImageGrungeTop>
      <Image imgSrc={BackgroundImage} alt={'background'} />
      <MoodImageGrungeBottom>
        <Image imgSrc={GrungeBottom} alt={'grunge background image'} />
      </MoodImageGrungeBottom>
    </MoodImage>

    <LayoutContent>
      <LayoutSection centered={true}>
        <BusCardWrapper>
          <BusOverviewCard
            images={[kleinbus1, kleinbus2, kleinbus3, kleinbus5]}
            alt={'alt'}
            category={'Kleinbus'}
            title={'VW T5 Kleinbus'}
            description={
              'Mit dem Kleinbus bis du für kurze Trips in der Stadt perfekt ausgestattet. Mit den maximal 8 Sitzen transportiert dieser Bus fast so viele Personen wie seine großen Brüder.'
            }
          />
          <BusOverviewCard
            images={[mittel1, mittel2, mittel3, mittel4, mittel5]}
            alt={'alt'}
            category={'Mittellang'}
            title={'MAN TGE Mittellang'}
            description={
              'Der mittellange MAN Bus bietet viel Flexibilität und Komfort. Er hat bis zu 8 Einzelsitze, die auf einer Airline Schiene nach belieben verschoben werden können. Dazu 360° Rückfahr-Kamera und Automatik-Getriebe.'
            }
          />
          <BusOverviewCard
            images={[lang1, lang2, lang3, lang4, lang5, lang6]}
            alt={'alt'}
            category={'Extralang'}
            title={'VW Crafter Extralang'}
            description={
              'Unser langer Bus bietet den höchsten Komfort! Viel Beinfreiheit, großer Stauraum und dazu modernste Entertainment-Technik lassen auch die längste Reise wie im Fluge vergehen.'
            }
          />
        </BusCardWrapper>
        <Button big rounded onClick={() => history.push('/busse')}>
          Zu den Bussen
        </Button>
      </LayoutSection>
    </LayoutContent>
  </BusIntroWrapper>
);

export default BusIntro;
