import { createAction } from './util/createAction';

export const [datePickerOpen] = createAction('DATEPICKER_OPEN', (isOpen) => ({
  isOpen,
}));

export const [createAnzeigeClick] = createAction(
  'CREATE_ANZEIGE_CLICK',
  (anzeige) => ({ anzeige })
);
