import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import omit from 'lodash/omit'
import { fonts } from '../theme/fonts'
import { breakpoints } from '../theme/breakpoints'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, omit(props,['noSpaceAfter', 'center']), children)
)`
  font-family: ${fonts.subline};
  font-size: 2.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
  margin-top: 0;
  margin-bottom: ${props => (!props.noSpaceAfter ? '1rem' : '0')};
  text-align: ${props => (props.center ? 'center' : 'left')};
  
  @media screen and (max-width: ${breakpoints.minTablet}) {
    text-align: center;
  }
`

export const H2 = ({ children, ...props }) => (
  <TagName {...props}>
    {children}
  </TagName>
)

H2.propTypes = {
  noSpaceAfter: PropTypes.bool,
  center: PropTypes.bool,
}

H2.defaultProps = {
  tag: 'h2'
}
