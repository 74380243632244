import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from '../theme/breakpoints'
import omit from 'lodash/omit'
import { fonts } from '../theme/fonts'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, omit(props,['noSpaceAfter']), children)
)`
  font-family: ${fonts.headline};
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: ${props => (!props.noSpaceAfter ? '1.8rem' : '0')};

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    font-size: 6rem;
  }
`

export const H1 = ({ children, ...props }) => (
  <TagName {...props}>{children}</TagName>
)

H1.propTypes = {
  noSpaceAfter: PropTypes.bool
}

H1.defaultProps = {
  tag: 'h1'
}
