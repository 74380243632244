import * as actions from '../actions'
import { createReducer } from './util/createReducer'

const initialState = {
  noScroll: false
}
export default createReducer(initialState, {
  [actions.datePickerOpen]: (state, { payload: { isOpen } }) => {
    return {
      ...state,
      noScroll: isOpen
    }
  }
})
