import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme/colors';

const LoaderStyle = styled.div`
  text-align: center;
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  max-width: 10rem;
  margin: 0 auto;

  #loader-2 {
    display: inline-block;
  }
  #loader-2 span {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: ${colors.darkGrey};
    margin: 35px 4px 5px;
  }

  #loader-2 span:nth-child(1) {
    animation: bounce 1s ease-in-out infinite;
  }

  #loader-2 span:nth-child(2) {
    animation: bounce 1s ease-in-out 0.33s infinite;
  }

  #loader-2 span:nth-child(3) {
    animation: bounce 1s ease-in-out 0.66s infinite;
  }

  @keyframes bounce {
    0%,
    75%,
    100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }

    25% {
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      -o-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  }
`;

const Text = styled.p`
  color: ${colors.darkGrey};
  margin-top: 0rem;
  letter-spacing: 0.5rem;
`;

const Loader = ({ noText = false, inline = false }) => (
  <LoaderStyle inline={inline}>
    <div className="loader" id="loader-2">
      <span />
      <span />
      <span />
    </div>
    {noText ? null : <Text>Lade</Text>}
  </LoaderStyle>
);

export default Loader;
