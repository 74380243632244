import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors } from '../theme/colors';

const SelectWrapper = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  line-height: 1.42rem;
  width: 100%;
  min-height: 2rem;

  color: ${(props) =>
    !!props.error ? colors.red : props.disabled ? colors.lightGrey : 'inherit'};
`;

const SelectStyled = styled.select`
  color: ${colors.darkGrey};
  min-width: 9rem;
  width: 100%;
  min-height: 4rem;
  appearance: none;

  margin: 0;
  padding: 1rem 4.9rem 1rem 1.9rem;

  border: 1px solid ${colors.silver};
  border-radius: 3px;
  background-color: transparent;
`;

const After = styled.span`
  margin-left: -4rem;
  color: ${colors.silver};
`;

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.initialValue };
  }

  onChange = (e) => {
    const value = e.target.value;
    this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    const { label, htmlId, options, afterLabel } = this.props;
    return (
      <SelectWrapper>
        <Label htmlFor={htmlId}>{label}</Label>
        <SelectStyled
          id={htmlId}
          onChange={this.onChange}
          value={this.state.value}
        >
          {options.map((option, index) => {
            return <option key={index}>{option}</option>;
          })}
        </SelectStyled>
        <After>{afterLabel}</After>
      </SelectWrapper>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  htmlId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  initialValue: PropTypes.string,
  afterLabel: PropTypes.string,
};

export default Select;
