import React from 'react'
import Mountain from '../../images/mountain.jpg'
import { LayoutContent, LayoutSection } from '../../ui/Layout'
import Stage from '../../ui/Stage/Stage'
import { H2 } from '../../ui/Typography'
import GoogleMap from '../../ui/GoogleMap/GoogleMap'

export function Kontakt() {
  return (
    <div>
      <Stage imgSrc={Mountain} alt={'Gruppenbus'} slogan={'Kontakt'} small />
      <LayoutContent>
        <LayoutSection>
          <H2 center>Unsere Adresse</H2>
          <GoogleMap />
        </LayoutSection>
      </LayoutContent>
    </div>
  )
}
