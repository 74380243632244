import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${(props) => (props.baseline ? '-1.1rem' : '0')};
`;

export const Rollstuhl = (props) => (
  <SvgContainer>
    <Svg viewBox="0 0 317.697 317.697" {...props}>
      <path
        d="M291.973,308.693c0,4.971-4.029,9-9,9h-28.204c-0.092,0.003-0.184,0.004-0.274,0.004c-1.097,0-2.169-0.202-3.168-0.579
	c-0.382-0.144-0.751-0.313-1.106-0.505c-1.67-0.903-3.055-2.33-3.887-4.131c-0.229-0.495-0.416-1.014-0.554-1.552l-28.043-101.076
	l-84.14-0.236c-0.878,0.018-1.771-0.112-2.601-0.354c-1.289-0.373-2.459-1.026-3.438-1.89c-1.016-0.894-1.834-2.017-2.367-3.301
	c-0.325-0.781-0.544-1.618-0.637-2.492l-12.303-101.9c-0.038-0.278-0.064-0.562-0.077-0.848c-0.217-4.665,3.202-8.775,7.912-9.345
	c0.476-0.057,0.948-0.078,1.41-0.06h1.47c0.236-0.001,0.472-0.001,0.703-0.001c22.135,0,24.774,0.5,39.656,13.438l1.61,1.398
	c0.073,0.063,0.146,0.129,0.216,0.194l60.084,55.601h14.055c4.971,0,9,4.029,9,9s-4.029,9-9,9h-17.552
	c-0.214-0.007-0.383-0.006-0.574-0.017c-0.895-0.053-1.754-0.237-2.56-0.533c-0.001-0.001-0.001-0.001-0.002-0.001
	c-0.004-0.002-0.009-0.003-0.014-0.005c-0.968-0.357-1.888-0.887-2.714-1.592c-0.002-0.002-0.003-0.003-0.005-0.005
	c-0.003-0.002-0.006-0.004-0.008-0.007c-0.007-0.005-0.013-0.01-0.019-0.015v-0.001c-0.084-0.072-0.167-0.146-0.248-0.221
	l-62.564-57.897l-1.516-1.316c-5.916-5.143-8.415-7.262-10.886-8.067c-1.934-0.63-4.85-0.846-9.308-0.918l10.162,84.176
	l83.126,0.233c4.034,0.012,7.568,2.706,8.647,6.594l28.084,101.226h21.631C287.944,299.693,291.973,303.723,291.973,308.693z
	 M204.745,256.1c-4.318-2.462-9.815-0.956-12.275,3.363c-14.143,24.816-40.651,40.232-69.182,40.232
	c-43.871,0-79.563-35.691-79.563-79.563c0-32.637,20.446-62.471,50.878-74.238c4.636-1.793,6.94-7.005,5.148-11.641
	c-1.794-4.637-7.003-6.939-11.641-5.148c-18.166,7.025-33.684,19.212-44.874,35.243c-11.456,16.413-17.512,35.703-17.512,55.784
	c0,53.796,43.767,97.563,97.563,97.563c34.981,0,67.482-18.898,84.82-49.32C210.569,264.057,209.063,258.561,204.745,256.1z
	 M83.636,37.528C83.636,16.835,100.472,0,121.166,0c20.692,0,37.527,16.835,37.527,37.528s-16.835,37.528-37.527,37.528
	C100.472,75.057,83.636,58.222,83.636,37.528z M101.636,37.528c0,10.768,8.761,19.528,19.529,19.528
	c10.768,0,19.527-8.761,19.527-19.528S131.933,18,121.166,18C110.397,18,101.636,26.761,101.636,37.528z"
      />
    </Svg>
  </SvgContainer>
);

Rollstuhl.propTypes = {
  baseline: PropTypes.bool,
};
