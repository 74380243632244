import React from 'react'
import styled from 'styled-components'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, props, children)
)`
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2.5rem;
  margin-bottom: ${props => (props.tag === 'p' ? '3rem' : false)};
`

export const Paragraph = ({ children, ...props }) => (
  <TagName {...props}>{children}</TagName>
)

Paragraph.defaultProps = {
  tag: 'p'
}
