import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'


const ImageStyled = styled.img`
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '100%'}
`

const Image = ({ imgSrc, alt, ...props }) => (
  <ImageStyled src={imgSrc} alt={alt} {...props} />
)

Image.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  maxWidth: PropTypes.string
}

export default Image
