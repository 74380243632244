import { createAction } from './util/createAction'

export const [calculateAllPrices] = createAction(
  'BUS_CALCULATE_ALL_PRICES',
  bookingData => ({
    bookingData
  })
)

export const [
  calculatePrice,
  calculatePriceSuccess,
  calculatePriceFailure
] = createAction(
  'BUS_CALCULATE_PRICE',
  (booking) => ({
    booking
  }),
  (busId, priceData) => ({
    busId,
    priceData
  })
)
