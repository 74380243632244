import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Clima = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 64 64" {...props}>
      <g>
        <path
          d="M32,27c-2.757,0-5,2.243-5,5s2.243,5,5,5s5-2.243,5-5S34.757,27,32,27z M32,35c-1.654,0-3-1.346-3-3   s1.346-3,3-3s3,1.346,3,3S33.654,35,32,35z"
        />
        <path
          d="M54,25H37.644c-0.289-0.234-0.597-0.445-0.914-0.642c2.663-2.064,4.27-5.239,4.27-8.669V1h-7   c-4.962,0-9,4.038-9,9v16.356c-0.234,0.289-0.445,0.597-0.642,0.914C22.294,24.607,19.118,23,15.689,23H1v7c0,4.963,4.038,9,9,9   h16.356c0.29,0.234,0.597,0.445,0.914,0.642C24.607,41.706,23,44.881,23,48.312V63h7c4.962,0,9-4.037,9-9V37.644   c0.234-0.29,0.445-0.597,0.642-0.914c2.064,2.663,5.239,4.27,8.669,4.27H63v-7C63,29.038,58.962,25,54,25z M32,39   c-3.86,0-7-3.141-7-7c0-3.86,3.14-7,7-7s7,3.14,7,7C39,35.859,35.86,39,32,39z M34,3h5v12.689c0,3.171-1.68,6.075-4.393,7.699   C33.781,23.138,32.907,23,32,23c-0.338,0-0.671,0.022-1,0.059V17c0-1.565,0.748-3.061,2-4l1.6-1.2l-1.2-1.6l-1.6,1.2   C30.047,12.715,29,14.808,29,17v6.525c-0.711,0.252-1.384,0.583-2,0.997V10C27,6.14,30.14,3,34,3z M3,30v-5h12.689   c3.171,0,6.075,1.68,7.699,4.393C23.138,30.219,23,31.093,23,32c0,0.338,0.022,0.671,0.059,1H17c-1.565,0-3.061-0.748-4-2l-1.2-1.6   l-1.6,1.2l1.2,1.6c1.315,1.753,3.408,2.8,5.6,2.8h6.525c0.252,0.711,0.583,1.384,0.997,2H10C6.14,37,3,33.859,3,30z M30,61h-5   V48.312c0-3.171,1.681-6.076,4.394-7.7C30.219,40.862,31.094,41,32,41c0.338,0,0.671-0.022,1-0.059V47c0,1.565-0.748,3.061-2,4   l-1.6,1.2l1.2,1.6l1.6-1.2c1.753-1.314,2.8-3.408,2.8-5.6v-6.525c0.711-0.252,1.384-0.583,2-0.997V54C37,57.859,33.86,61,30,61z    M61,39H48.311c-3.171,0-6.075-1.68-7.699-4.393C40.862,33.781,41,32.907,41,32c0-0.338-0.022-0.671-0.059-1H47   c1.565,0,3.061,0.748,4,2l1.2,1.6l1.6-1.199L52.6,31.8C51.285,30.047,49.192,29,47,29h-6.525c-0.252-0.711-0.583-1.384-0.997-2H54   c3.86,0,7,3.14,7,7V39z"
        />
        <path
          d="M56.141,17.819l1.724-1.015c-3.313-5.628-8.457-10.09-14.485-12.564l-0.759,1.851   C48.247,8.399,53.048,12.564,56.141,17.819z"
        />
        <path
          d="M57.909,21.38l1.85-0.76c-0.264-0.642-0.554-1.284-0.862-1.909l-1.793,0.887   C57.391,20.181,57.662,20.781,57.909,21.38z"
        />
        <path
          d="M50.149,19.563l1.648-1.132c-2.135-3.111-5.012-5.708-8.318-7.512l-0.958,1.756   C45.553,14.329,48.19,16.711,50.149,19.563z"
        />
        <path
          d="M52.251,23.392l1.84-0.784c-0.302-0.709-0.642-1.411-1.01-2.086l-1.756,0.958   C51.663,22.099,51.974,22.742,52.251,23.392z"
        />
        <path
          d="M20.414,6.509l-0.829-1.82C12.601,7.869,7.151,13.526,4.24,20.621l1.851,0.759   C8.808,14.758,13.895,9.477,20.414,6.509z"
        />
        <path
          d="M23.31,5.382L22.69,3.48c-0.554,0.18-1.1,0.377-1.639,0.588l0.729,1.862   C22.284,5.733,22.793,5.55,23.31,5.382z"
        />
        <path
          d="M10.919,20.521l1.756,0.958c1.654-3.032,4.037-5.671,6.891-7.629L18.435,12.2   C15.322,14.336,12.724,17.214,10.919,20.521z"
        />
        <path
          d="M9.792,49.045l-1.586,1.219c3.232,4.206,7.525,7.49,12.415,9.496l0.759-1.85   C16.817,56.037,12.81,52.972,9.792,49.045z"
        />
        <path
          d="M7.483,45.521l-1.75,0.969c0.387,0.7,0.808,1.394,1.25,2.06l1.667-1.104   C8.237,46.823,7.845,46.176,7.483,45.521z"
        />
        <path
          d="M13.851,44.437l-1.648,1.133c2.136,3.109,5.012,5.707,8.318,7.512l0.958-1.756   C18.448,49.671,15.81,47.289,13.851,44.437z"
        />
        <path
          d="M44.4,57.104l0.887,1.793c6.559-3.245,11.699-8.756,14.473-15.517l-1.851-0.76   C55.32,48.931,50.522,54.074,44.4,57.104z"
        />
        <path
          d="M40.689,58.619l0.621,1.9c0.704-0.229,1.4-0.486,2.07-0.762l-0.762-1.85   C41.996,58.165,41.346,58.404,40.689,58.619z"
        />
        <path
          d="M53.081,43.479l-1.756-0.957c-1.845,3.382-4.559,6.234-7.847,8.25l1.045,1.705   C48.109,50.278,51.068,47.167,53.081,43.479z"
        />
      </g>
    </Svg>
  </SvgContainer>
)

Clima.propTypes = {
  baseline: PropTypes.bool
}
