import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Button from '../../ui/Button/Button';
import { colors } from '../../ui/theme/colors';
import {
  Breite,
  CarSeatWhite,
  Case,
  ChevronR,
  Clima,
  Diesel,
  Hoehe,
  Info,
  Radio,
  Transmission,
} from '../../ui/Icon';
import { breakpoints } from '../../ui/theme/breakpoints';
import { rgba } from '../../ui/theme/utils';
import { H2, Paragraph } from '../../ui/Typography';
import { fonts } from '../../ui/theme/fonts';
import ImageSlider from '../../ui/ImageSlider/ImageSlider';
import { useSelector } from 'react-redux';
import Loader from '../../ui/Loader/Loader';
import { CarParking } from '../../ui/Icon/CarParking';

const SectionCard = styled.section`
  width: 100%;
  padding: 2rem 0;
  border: 1px solid ${colors.lightSilver};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem auto 0;
  box-sizing: border-box;
`;

const InfoText = styled.div`
  width: 43.33333%;
  max-width: 43.33333%; /*necessary for image slider*/
  flex: 0 0 43.33333%;
  padding: 0 2rem;

  @media screen and (max-width: ${breakpoints.minDesktop}) {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;

    > span,
    > button {
      display: none;
    }
  }
`;

const BusFeatureIcons = styled.div`
  width: 30.66667%;
  flex: 0 0 30.66667%;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: ${breakpoints.minDesktop}) {
    width: 50%;
    flex: 0 0 50%;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
  text-align: center;

  > span svg {
    width: 3rem;
    height: 3rem;
    margin: 0.8rem;
    top: 0;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 33%;
    margin-bottom: 2rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-left: 1px solid ${colors.lightGrey};

    :nth-child(3n + 1) {
      border-left: none;
    }

    > span svg {
      width: 4rem;
      height: 4rem;
      margin: 0.8rem;
    }
  }

  @media screen and (max-width: ${breakpoints.minPhone}) {
    > span svg {
      width: 3rem;
      height: 3rem;
      margin: 0.5rem;
    }
  }
`;

const PriceBoxWrapper = styled.div`
  width: 25.66667%;
  padding: 0 2rem 0 1rem;
  flex: 0 0 25.66667%;

  @media screen and (max-width: ${breakpoints.minDesktop}) {
    width: 100%;
    flex: 0 0 100%;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const PriceBox = styled.div`
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid ${colors.lightSilver};
  background: ${colors.lightGrey};

  > p {
    color: ${colors.darkGrey};
    font-size: 1.4rem;
  }

  > div {
    text-align: center;
  }

  > div span {
    font-family: ${fonts.headline};
    font-size: 2.7rem;
  }

  > button {
    align-self: stretch;
    width: calc(100% + 2px);
    margin-top: 1rem;
  }

  @media screen and (max-width: ${breakpoints.minTablet}) {
    border: 1px solid ${rgba(colors.lightGrey, 0.45)};
    margin: 1rem auto 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
`;

const SubInfo = styled.small`
  color: ${colors.silver};
  display: block;
  margin-bottom: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${colors.silver};
    top: 0.2rem;
  }
`;

const KilometerInkl = styled.small`
  color: ${colors.darkGrey};
`;

const BusResultCard = ({ id, onClick }) => {
  const booking = useSelector((store) => store.Booking.store);
  const busses = useSelector((store) => store.Bus.store);
  return (
    <SectionCard>
      <InfoText>
        <H2>{busses[id].name}</H2>
        <ImageSlider images={busses[id].imgs} />
        <SubInfo>
          <Info /> Farben können abweichen
        </SubInfo>
        <Paragraph>{busses[id].description}</Paragraph>
      </InfoText>
      <BusFeatureIcons>
        <IconWrapper>
          <CarSeatWhite baseline />
          <span>{busses[id].persons} Sitze</span>
        </IconWrapper>
        <IconWrapper>
          <Breite baseline />
          <span>Länge {busses[id].length}</span>
        </IconWrapper>
        <IconWrapper>
          <Hoehe baseline />
          <span>Höhe {busses[id].height}</span>
        </IconWrapper>
        <IconWrapper>
          <Clima baseline />
          <span>Klima</span>
        </IconWrapper>
        <IconWrapper>
          <Case baseline />
          <span>Zuladung {busses[id].storage} kg</span>
        </IconWrapper>
        <IconWrapper>
          <Transmission baseline />
          <span>{busses[id].gear}</span>
        </IconWrapper>
        <IconWrapper>
          <Diesel baseline />
          <span>{busses[id].fuel}</span>
        </IconWrapper>
        <IconWrapper>
          <Radio baseline />
          <span>{busses[id].radio}</span>
        </IconWrapper>
        {busses[id].parkingCamera && (
          <IconWrapper>
            <CarParking baseline />
            <span>Rückfahrkamera</span>
          </IconWrapper>
        )}
      </BusFeatureIcons>
      <PriceBoxWrapper>
        <PriceBox>
          <p>GESAMT</p>
          <div>
            <span>
              {busses[id].price ? (
                busses[id].price.toFixed(2).replace('.', ',')
              ) : (
                <Loader />
              )}{' '}
              €
            </span>
            <br />
            <KilometerInkl>
              {busses[id].days} Miettage <br />
              Preis pro Tag{' '}
              {(busses[id].price / busses[id].days)
                .toFixed(2)
                .replace('.', ',')}{' '}
              €<br />
              Inkl. {Math.max(busses[id].freeKm, booking.km)} km
            </KilometerInkl>
          </div>
          <Button big onClick={() => onClick(id)} Icon={ChevronR}>
            Wählen
          </Button>
        </PriceBox>
      </PriceBoxWrapper>
    </SectionCard>
  );
};

BusResultCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default BusResultCard;
