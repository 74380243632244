import { takeEvery } from 'redux-saga/effects'

export default function* CatchErrorSaga() {
  yield takeEvery(action => action.type.match(/ERROR|FAILURE/), handleError)
}

function handleError({ error }) {
  if (error) {
    console.error(error)
  }
}
