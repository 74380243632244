import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Height = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 1000 1000" {...props}>
      <g>
        <path d="M565.2,321.4c-30.5-33.8-74.2-53.2-119.7-53.2h-90.9c-45.5,0-89.2,19.4-119.7,53.2l-146.3,162c-7.3,8.1-7,20.5,0.7,28.2l49.8,49.8c4,4,9.4,6.3,15.1,6c5.7-0.2,11-2.7,14.7-7l105-120v529.2c0,11.3,9.1,20.5,20.4,20.5H365c11.3,0,20.4-9.1,20.4-20.5V661.3h29.5v308.3c0,11.3,9.1,20.5,20.4,20.5h70.4c11.3,0,20.4-9.1,20.4-20.5V440.4l105,120c3.8,4.3,9.1,6.8,14.8,7c5.5,0.4,11.1-2,15.1-6l49.8-49.8c7.7-7.7,8-20.1,0.7-28.2L565.2,321.4z M647.6,517L521.1,372.5c-5.6-6.4-14.6-8.7-22.5-5.7c-8,3-13.3,10.6-13.3,19.2v563.2h-29.5h-0.1V640.8c0-11.3-9.1-20.5-20.4-20.5h-70.4c-11.3,0-20.4,9.1-20.4,20.5v308.3h-29.5V385.9c0-8.5-5.3-16.2-13.2-19.2c-8-3-17-0.7-22.6,5.7L152.6,517L132,496.4l133.3-147.6c22.8-25.2,55.4-39.7,89.4-39.7h90.9c34,0,66.6,14.5,89.4,39.7l133.3,147.6L647.6,517z" />
        <path d="M896.2,10h-94c-11.3,0-20.5,9.1-20.5,20.5c0,11.3,9.1,20.4,20.5,20.4h73.5v193.9h-50c-11.3,0-20.5,9.2-20.5,20.5c0,11.3,9.1,20.4,20.5,20.4h50v193.9h-50c-11.3,0-20.5,9.1-20.5,20.4s9.1,20.5,20.5,20.5h50v193.9h-50c-11.3,0-20.5,9.1-20.5,20.4s9.1,20.5,20.5,20.5h50v193.9h-73.5c-11.3,0-20.5,9.1-20.5,20.4c0,11.3,9.1,20.5,20.5,20.5h94c11.3,0,20.4-9.1,20.4-20.5V30.4C916.6,19.1,907.5,10,896.2,10z" />
        <path d="M400.1,238.7c63,0,114.4-51.3,114.4-114.4c0-63-51.3-114.4-114.4-114.4c-63.1,0-114.4,51.3-114.4,114.4C285.8,187.4,337.1,238.7,400.1,238.7z M400.1,50.9c40.5,0,73.5,32.9,73.5,73.5c0,40.5-33,73.5-73.5,73.5c-40.5,0-73.5-33-73.5-73.5C326.7,83.8,359.6,50.9,400.1,50.9z" />
      </g>
    </Svg>
  </SvgContainer>
)

Height.propTypes = {
  baseline: PropTypes.bool
}
