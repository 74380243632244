import * as rax from 'retry-axios';
import axios from 'axios';

/**
 *
 * @param data
 * @returns {IterableIterator<Promise<AxiosResponse<T>>>}
 * {"price":655,"priceBus":425,"priceZKm":110,"days":5,"freeKm":500,"versicherung":75}
 */
export async function requestPrice(data) {
  rax.attach();
  const params = {
    token: 'assadlklnn34nsdf!sdfsd',
    buchung: {
      start: data.startDate.format('YYYY-MM-DD'),
      start_zeit: data.startTime,
      ende: data.endDate.format('YYYY-MM-DD'),
      ende_zeit: data.endTime,
      bus: data.bus,
      km: data.km,
      navi: data.navi ? 1 : undefined,
      schneeketten: data.schneeketten ? 1 : undefined,
      wechselrichter: data.wechselrichter ? 1 : undefined,
      versicherung: data.versicherung ? 1 : undefined,
      kindersitz: data.kindersitz ? 1 : undefined,
      laderampe: data.laderampe ? 1 : undefined,
      Rollstuhlhaltesystem: data.Rollstuhlhaltesystem ? 1 : undefined,
    },
  };

  return axios.post('https://api.gruppenbus.com/preisForBuchung', params, {
    timeout: 5000,
  });
}

export async function createBooking(booking) {
  rax.attach();
  const params = {
    token: 'assadlklnn34nsdf!sdfsd',
    buchung: {
      ...booking,
      start: booking.startDate.format('YYYY-MM-DD'),
      start_zeit: booking.startTime,
      ende: booking.endDate.format('YYYY-MM-DD'),
      ende_zeit: booking.endTime,
      navi: booking.navi ? 1 : undefined,
      schneeketten: booking.schneeketten ? 1 : undefined,
      wechselrichter: booking.wechselrichter ? 1 : undefined,
      versicherung: booking.versicherung ? 1 : undefined,
      kindersitz: booking.kindersitz ? 1 : undefined,
      laderampe: booking.laderampe ? 1 : undefined,
      Rollstuhlhaltesystem: booking.Rollstuhlhaltesystem ? 1 : undefined,
    },
  };

  return axios.post('https://api.gruppenbus.com/booking', params, {
    timeout: 5000,
  });
}

export async function createAnzeigeClick(anzeige) {
  rax.attach();
  const params = {
    token: 'assadlklnn34nsdf!sdfsd',
    anzeige,
  };

  return axios.post('https://api.gruppenbus.com/anzeigeClick', params, {
    timeout: 5000,
  });
}
