import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Hoehe = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 512 512" {...props}>
      <g>
        <polygon
          points="401,0 111,0 111,40 254.716,40 152.469,142.246 180.754,170.53 235,116.284 235,396.104 180.754,341.858 
			152.469,370.142 254.328,472 111,472 111,512 401,512 401,472 255.672,472 357.531,370.142 329.246,341.858 275,396.104 
			275,116.284 329.246,170.53 357.531,142.246 255.284,40 401,40 		"
        />
      </g>
    </Svg>
  </SvgContainer>
)

Hoehe.propTypes = {
  baseline: PropTypes.bool
}
