import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Transmission = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 1000 1000" {...props}>
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M734.7,4996.8l-97-10.2v-905.9v-903.3h204.2h204.2v357.3v357.3h104.6c178.6,0,255.2-63.8,303.7-255.2c17.9-63.8,53.6-191.4,81.7-285.8l48.5-173.5h211.8h214.4l-45.9,160.8c-25.5,86.8-71.4,245-102.1,349.6c-40.8,140.4-79.1,211.8-142.9,270.5l-84.2,81.7l99.5,66.3c191.4,125,257.7,390.4,155.7,617.6c-97,211.8-316.4,293.5-765.6,285.8C961.9,5007,785.8,5001.9,734.7,4996.8z M1469.7,4624.2c66.4-76.6,81.7-214.4,35.7-301.1c-43.4-76.6-163.3-125-319-125h-140.3v257.7v257.7l181.2-10.2C1388,4695.6,1416.1,4685.4,1469.7,4624.2z" />
          <path d="M5843.5,4989.1c-63.8-12.8-163.3-43.4-219.4-63.8l-107.2-40.8l40.8-153.1c33.2-117.4,51-148,81.7-135.2c239.9,104.6,415.9,114.8,520.6,33.2c68.9-53.6,86.7-142.9,40.8-227.1c-33.2-61.2-158.2-102.1-308.8-102.1h-125V4147v-153.1h137.8c76.6,0,168.4-10.2,201.6-23c145.4-56.1,211.8-211.8,145.4-336.9c-68.9-127.6-313.9-160.8-561.4-74c-76.6,25.5-142.9,45.9-142.9,43.4c-2.6-2.5-23-71.5-43.4-153.1l-40.8-150.6l94.4-38.3c285.8-119.9,696.6-94.4,918.7,51c313.9,211.8,306.2,622.7-12.8,786l-132.7,68.9l71.5,30.6c247.5,102.1,319,423.6,142.9,635.4C6427.9,4971.2,6106.4,5042.7,5843.5,4989.1z" />
          <path d="M3452.5,4879.4c-130.1-58.7-234.8-119.9-237.3-137.8c0-17.9,12.8-86.8,28.1-158.2l25.5-125l163.3,74c91.9,40.8,171,74,178.6,74s12.8-316.4,12.8-701.8v-701.8h191.4h191.4V4096v893.1H3848C3712.7,4989.1,3648.9,4971.2,3452.5,4879.4z" />
          <path d="M8280.6,4958.5c-5.1-17.9-28.1-193.9-51-390.4c-23-196.5-48.5-387.9-56.1-423.6l-12.8-63.8H8375c349.6,2.6,530.8-99.5,530.8-301.1c0-94.4-122.5-216.9-242.4-245c-112.3-28.1-280.7-10.2-423.6,40.8l-94.4,30.6l-30.6-150.6c-17.9-84.2-25.5-158.2-17.9-165.9c33.2-35.7,303.7-86.8,454.2-86.8c451.7,0,763,250.1,763,615c0,176.1-30.6,265.4-122.5,362.3c-109.7,117.4-252.6,176.1-464.4,194l-191.4,15.3l7.7,89.3c17.9,186.3-2.5,178.6,377.7,178.6h342v165.9v165.9h-484.9C8410.7,4989.1,8288.2,4981.4,8280.6,4958.5z" />
          <path d="M1084.3,2506.2c-56.1-33.2-125-102.1-158.2-158.2c-58.7-102.1-58.7-107.2-58.7-1260.6c0-1148.3,0-1161.1,56.1-1240.2c28.1-45.9,91.9-107.2,140.4-140.4c168.4-114.8,285.8-127.6,1275.9-127.6h926.3v-872.7v-870.2l61.2-104.6c145.5-250.1,500.2-296,714.5-97c140.4,130.2,142.9,158.2,142.9,1089.6v854.9h740h740v-872.7v-870.2l61.3-104.6c188.8-321.5,699.2-278.2,829.4,68.9c17.8,46,28.1,385.3,28.1,926.3v852.3h740h740v-872.7v-870.2l63.8-109.7c171-290.9,638-283.3,796.2,15.3l58.7,109.7V38.5v2187l-58.7,112.3c-160.8,301.1-632.9,306.2-801.3,10.2c-56.1-99.5-58.7-117.4-58.7-974.8V497.8h-740h-740v860c0,819.1-2.5,865.1-53.6,969.7c-150.6,308.8-635.4,321.5-806.4,20.4c-56.2-99.5-58.7-117.4-58.7-974.8V497.8h-740h-740v860c0,819.1-2.6,865.1-53.6,969.7c-150.6,308.8-635.4,321.5-806.4,20.4c-56.1-99.5-58.7-117.4-58.7-974.8V497.8h-740h-740v860c0,819.1-2.6,865.1-53.6,969.7C1625.3,2549.5,1314,2636.3,1084.3,2506.2z" />
          <path d="M8811.3-3011c-316.4-68.9-558.8-260.3-691.5-543.6c-63.8-132.7-68.9-173.5-68.9-451.7c0-283.2,5.1-316.4,68.9-433.8c81.7-158.2,153.1-224.6,308.8-293.5c265.4-117.4,620.1-45.9,793.6,158.2c109.7,125,150.5,280.7,137.8,489.9c-12.8,201.6-102.1,349.6-262.8,433.8c-148,79.1-423.6,74-553.8-5.1l-94.4-58.7l25.5,76.5c15.3,43.4,79.1,125,140.4,178.6c122.5,107.2,265.4,155.6,454.3,155.6h117.4v165.9v165.9l-107.2-2.6C9018-2975.2,8898.1-2993.1,8811.3-3011z M8880.2-3955.2c56.2-56.1,63.8-86.8,63.8-232.2c0-155.7-5.1-171-84.2-242.4c-91.9-84.2-153.1-91.9-260.3-35.7c-86.8,45.9-130.1,114.8-155.7,255.2C8392.9-3934.7,8686.3-3763.7,8880.2-3955.2z" />
          <path d="M3278.9-3033.9c-132.7-40.8-267.9-112.3-267.9-140.4c0-12.8,23-79.1,53.6-150.6l53.6-127.6l114.8,61.3c165.9,86.7,382.8,86.7,477.2-2.6c53.6-51.1,66.4-84.2,66.4-186.3c0-173.5-91.9-298.6-474.6-663.5l-316.4-296V-4662v-122.5h625.2h625.2v165.9v165.9h-331.7h-331.7l239.9,237.3c178.6,173.5,260.3,275.6,308.8,382.8c160.8,349.6,35.7,696.7-290.9,806.4C3717.9-2988,3409.1-2993.1,3278.9-3033.9z" />
          <path d="M5986.4-3130.9c-242.4-382.8-533.3-852.3-564-916.1c-20.4-38.3-38.3-122.5-38.3-188.8v-114.8h395.5h395.5v-204.1V-4759h191.4h191.4v204.2v204.1h114.8h114.8v153.1v153.1h-114.8h-114.8v523.1v523.1h-242.4h-242.4L5986.4-3130.9z M6180.4-3692.3l-7.6-352.2h-191.4c-102.1,0-188.8,7.7-188.8,15.3c0,7.7,56.1,109.7,125,224.6c68.9,112.3,155.7,267.9,193.9,341.9c35.7,76.5,68.9,132.7,71.4,127.6C6185.5-3337.6,6185.5-3500.9,6180.4-3692.3z" />
        </g>
      </g>
    </Svg>
  </SvgContainer>
)

Transmission.propTypes = {
  baseline: PropTypes.bool
}
