import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { createBooking } from '../config/api';

export default function* BookingSaga() {
  yield takeEvery(actions.createBooking, createBookingRequest);
}

function* createBookingRequest({ payload: { booking } }) {
  if (booking.startDate && booking.endDate && booking.bus) {
    const result = yield call(createBooking, booking);
    if (result) {
      yield put(actions.createBookingSuccess(result.data));
    }
  } else {
    yield put(actions.createBookingFailure(new Error('Kein Bus gewählt')));
  }
}
