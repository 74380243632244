import React from 'react'
import PropTypes from 'prop-types'
import { breakpoints } from '../theme/breakpoints'
import styled from 'styled-components'

const SectionStyled = styled.section`
  width: 100%;
  max-width: ${props =>
    props.narrow ? '46rem' : '120rem'};
  margin: 0 auto;
  padding: 0;
  box-sizing: 'border-box';
  display: ${props => (props.centered ? 'flex' : 'block')};
  flex-direction: ${props => (props.centered ? 'column' : 'none')};
  align-items: ${props => (props.centered ? 'center' : 'none')};
  text-align: ${props => (props.centered ? 'center' : 'auto')};

  @media screen and (min-width: ${breakpoints.minTablet}) {
    padding-left: ${props => (props.narrow ? '9.3rem' : '3.2rem')};
    padding-right: ${props => (props.narrow ? '9.3rem' : '3.2rem')};
    width: auto
    max-width: none
  }

  @media screen and (min-width: ${breakpoints.minDesktop}) {
    padding-left: ${props => (props.narrow ? '12.5rem' : '3.2rem')};
    padding-right: ${props => (props.narrow ? '12.5rem' : '3.2rem')};
    width: auto
    max-width: none
  }
`

export const LayoutSection = ({ children, ...props }) => (
  <SectionStyled {...props}>{children}</SectionStyled>
)

LayoutSection.propTypes = {
  centered: PropTypes.bool,
  fullWidth: PropTypes.bool,
  narrow: PropTypes.bool
}
