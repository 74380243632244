import React from 'react';
import styled from 'styled-components';
import { colors } from '../../ui/theme/colors';
import { H3, Paragraph } from '../../ui/Typography';
import Image from '../../ui/Image/Image';
import { fonts } from '../../ui/theme/fonts';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CardBoxStyled = styled.div`
  width: 100%;
  max-width: 55rem;
  padding: 2rem;
  border: 1px solid ${colors.lightSilver};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 1rem;
  text-align: left;
`;

const Price = styled.span`
  background: ${(props) => (props.plain ? 'none' : colors.primary)};
  color: ${(props) => (props.plain ? colors.semiBlack : colors.white)};
  padding: ${(props) => (props.plain ? '0' : '0.2rem 2rem 0.6rem')};
  border-radius: 2rem;
  font-family: ${(props) => (props.plain ? 'inherit' : fonts.headline)};
  font-size: ${(props) => (props.plain ? 'inherit' : '2rem')};
  min-width: 6rem;
  text-align: right;
`;

const Pre = styled.pre`
  color: ${colors.semiBlack};
  text-align: right;
  min-width: 6rem;
  white-space: pre-wrap;
`;
const Label = styled.span`
  color: ${colors.semiBlack};
  font-size: 1.8rem;
  font-weight: bold;
`;

const Hr = styled.hr`
  border: none;
  border-top: 1px solid ${colors.lightSilver};
  width: 100%;
  height: 1px;
  margin: 1rem 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  margin-bottom: 1rem;
  border-bottom: ${(props) =>
    props.bordered ? `1px solid ${colors.lightSilver}` : 'none'};
`;

const ParagraphStyled = styled(Paragraph)`
  font-size: 1.6rem;
  color: ${colors.darkGrey};
  margin: 0;
  min-width: 12rem;
`;

const Subline = styled.div`
  color: ${colors.silver};
  font-size: 1.3rem;
  text-align: right;
  padding-right: 1rem;
`;

export function BookingBox({ extended }) {
  const booking = useSelector((store) => store.Booking.store);
  const bus = useSelector((store) => store.Bus.store[booking.bus]);
  if (bus) {
    return (
      <React.Fragment>
        <CardBoxStyled>
          {extended && (
            <div>
              <Row centered>
                <Image imgSrc={bus.img} alt={bus.name} maxWidth={'350px'} />
              </Row>
              <Row>
                <Label>Personendaten</Label>
              </Row>
              <Row bordered>
                <ParagraphStyled>Vorname</ParagraphStyled>
                <Price plain>{booking.vname}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>Nachname</ParagraphStyled>
                <Price plain>{booking.nname}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>Straße</ParagraphStyled>
                <Price plain>{booking.strasse}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>PLZ</ParagraphStyled>
                <Price plain>{booking.plz}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>Ort</ParagraphStyled>
                <Price plain>{booking.ort}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>Telefon</ParagraphStyled>
                <Price plain>{booking.tel}</Price>
              </Row>
              <Row bordered>
                <ParagraphStyled>E-Mail</ParagraphStyled>
                <Price plain>{booking.email}</Price>
              </Row>
              {booking.kommentar && (
                <Row>
                  <ParagraphStyled>Kommentar</ParagraphStyled>
                  <Pre>{booking.kommentar}</Pre>
                </Row>
              )}
            </div>
          )}
          <Row>
            <Label>Fahrzeug</Label>
          </Row>
          <Row>
            <ParagraphStyled>{bus.name}</ParagraphStyled>
            <Price plain>
              {bus && bus.priceBus
                ? bus.priceBus.toFixed(2).replace('.', ',')
                : '-,--'}{' '}
              €
            </Price>
          </Row>
          <Row>
            <ParagraphStyled>
              Reisezeit:
              <br />{' '}
              {booking.startDate &&
                booking.startDate.format('DD.MM.YYYY')} bis{' '}
              {booking.endDate && booking.endDate.format('DD.MM.YYYY')}
            </ParagraphStyled>
          </Row>
          <Row>
            <ParagraphStyled>
              {bus.days} Tage <br />
            </ParagraphStyled>
          </Row>
          <Row>
            <ParagraphStyled>
              {Math.max(bus.freeKm, booking.km)} km inklusive <br />
              (0,25 €/Überkilometer)
            </ParagraphStyled>
            <Price plain>
              {bus && bus.priceZKm
                ? bus.priceZKm.toFixed(2).replace('.', ',')
                : '0,00'}{' '}
              €
            </Price>
          </Row>
          <Hr />
          <Row>
            <Label>Extras</Label>
          </Row>

          {booking.versicherung && (
            <Row>
              <ParagraphStyled>Reduktion SB 500 €</ParagraphStyled>
              <Price plain>
                {bus.versicherung.toFixed(2).replace('.', ',')} €
              </Price>
            </Row>
          )}
          {booking.navi && (
            <Row>
              <ParagraphStyled>Navi</ParagraphStyled>
              <Price plain>10,00 €</Price>
            </Row>
          )}
          {booking.schneeketten && (
            <Row>
              <ParagraphStyled>Schneeketten</ParagraphStyled>
              <Price plain>25,00 €</Price>
            </Row>
          )}
          {booking.wechselrichter && (
            <Row>
              <ParagraphStyled>12V Adapter</ParagraphStyled>
              <Price plain>10,00 €</Price>
            </Row>
          )}
          {booking.kindersitz && (
            <Row>
              <ParagraphStyled>Kindersitz</ParagraphStyled>
              <Price plain>15,00 €</Price>
            </Row>
          )}
          {booking.laderampe && (
            <Row>
              <ParagraphStyled>Laderampe</ParagraphStyled>
              <Price plain>15,00 €</Price>
            </Row>
          )}
          {booking.Rollstuhlhaltesystem && (
            <Row>
              <ParagraphStyled>Rollstuhlhaltesystem</ParagraphStyled>
              <Price plain>15,00 €</Price>
            </Row>
          )}
          <Hr />
          <Row>
            <Label>GESAMT</Label>
            <Price>
              {bus && bus.price
                ? bus.price.toFixed(2).replace('.', ',')
                : '-,--'}
              €
            </Price>
          </Row>
        </CardBoxStyled>
        <Subline> Alle Preise inkl. 19% MwSt.</Subline>
      </React.Fragment>
    );
  } else {
    return <H3>Kein Bus gewählt</H3>;
  }
}

BookingBox.propTypes = {
  extended: PropTypes.bool,
};

export default BookingBox;
