
export function createAction(
  key,
  payloadTransform = () => ({}),
  successTransform = () => ({}),
  errorTransform,
) {
  return [
    actionCreatorFactory(key, payloadTransform),
    actionCreatorFactory(`${key}_SUCCESS`, successTransform),
    actionCreatorFactory(
      `${key}_FAILURE`,
      errorTransform && ((err, ...args) => errorTransform(...args)),
    ),
  ];
}

function actionCreatorFactory(type, payloadTransform) {
  const actionCreator = (...args) => ({
    type,
    ...(payloadTransform && { payload: payloadTransform(...args) }),
    ...(args[0] instanceof Error && { error: args[0] }),
  });
  actionCreator.toString = () => type;
  return actionCreator;
}
