import React from 'react'
import styled from 'styled-components'

const e = React.createElement

const TagName = styled(({ tag, children, ...props }) =>
  e(tag, props, children)
)`
  text-decoration: underline;
`

export const A = ({ children, ...props }) => (
  <TagName {...props}>{children}</TagName>
)

A.defaultProps = {
  tag: 'a'
}
