import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => props.baseline ? '-1.1rem' : '0'};
`

export const Menu = (props) => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <path
        d="M2 4.5h16M2 10h16M2 15.5h16"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </Svg>
  </SvgContainer>
)

Menu.propTypes = {
  baseline: PropTypes.bool
}