export const colors = {
  primary: "#a1b543",
  primaryLight: "#c6da6d",
  primaryLighter: "#e6eec8",
  red: "#ff4e61",
  orange: "#f28e2a",
  black: "#181818",
  semiBlack: "#4A4A4A",
  white: "#ffffff",
  lightGrey: "#F2F2F2",
  lightSilver: "#e5e5e5",
  silver: "#c8cacb",
  darkGrey: "#8d8e8f"
};
