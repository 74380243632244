import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SvgContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  position: relative;
  top: ${props => (props.baseline ? '-1.1rem' : '0')};
`

export const Breite = props => (
  <SvgContainer>
    <Svg width={20} height={20} viewBox="0 0 512 512" {...props}>
      <g>
        <polygon points="472,111 472,254.716 369.754,152.47 341.469,180.754 395.716,235 115.896,235 170.143,180.754 141.857,152.47
			40,254.327 40,111 0,111 0,401 40,401 40,255.673 141.857,357.53 170.143,329.246 115.896,275 395.716,275 341.469,329.246
			369.754,357.53 472,255.284 472,401 512,401 512,111 		"/>
      </g>
    </Svg>
  </SvgContainer>
)

Breite.propTypes = {
  baseline: PropTypes.bool
}
